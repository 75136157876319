import { Injectable, EventEmitter } from '@angular/core';
import { IModalGenerico } from '../../nucleo/interface/IModalGenerico';
import { ModalGenericoComponent } from './modal-generico.component';

@Injectable({
    providedIn: 'root'
  })

export class ModalGenericoService {
    public titulo: string;
    public accion: string;
    public oculto: string = 'ocultar-modal';
    public notificacion = new EventEmitter<IModalGenerico>();
    private modalGenericoComponent: ModalGenericoComponent;
    public data: any;

    constructor() {

    }

    registrarComponente(componente: ModalGenericoComponent) {
      this.modalGenericoComponent = componente;
    }

    ocultarModal() {
        this.oculto = 'ocultar-modal';
        this.titulo = null;
        this.accion = null;
    }

    mostrarModal(accion: string,  titulo: string = 'Administrar Afiliación', data?: any): Promise<IModalGenerico> {
        let promesa = new Promise<IModalGenerico>((resolve)=> {
          this.oculto = '';
          this.accion = accion;
          this.titulo = titulo;
          this.data = data;
         // this.modalGenericoComponent.cargarCombo();
          this.modalGenericoComponent.modal = {
            tipoBloqueo: '', valorSeguridad: '', valorSeguridadSgte:''}

          this.notificacion.subscribe( response => {
            resolve(response)
          })
        })
    return (promesa)
    }
}