import { Component, OnInit, Input, ViewEncapsulation } from "@angular/core";
import { ServicioService } from "../../servicio/servicio.service";
import { Constante } from "../../nucleo/constante/Constante";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { finalize } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { Utilitario } from "../../nucleo/util/utilitario";
import { AutenticacionService } from "../../seguridad/autenticacion.service";
import { ModalConfirmarComponent } from "../modal-confirmar/modal-confirmar.component";
import { ModalConfirmarSiNoComponent } from "../modal-confirmar-si-no/modal-confirmar-si-no.component";
import { saveAs } from "file-saver";
import { ReCaptchaV3Service } from "ng-recaptcha";

@Component({
  selector: "app-modal-precio-nuevo",
  templateUrl: "./modal-precio-nuevo.component.html",
  styleUrls: ["./modal-precio-nuevo.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class ModalPrecioNuevoComponent implements OnInit {
  showGeneralProgres = false;
  mostrarProductoFiltrado = false;
  listaProductosFiltrados = [];
  tipoEstablecimiento = [];
  filtro: any = {};
  entidad: any = {};
  tipoForm: any;
  utilitario = new Utilitario();
  @Input() public producto: any;
  codigoEstablecimiento: string;
  catCodigoEstablecimiento: string;
  activeInput = false;
  tipoPrecio: number;
  criterio = "2";
  precioUnitarioReadOnly = false;
  keyword = "nombreProducto";
  notFoundResult = "No hay resultados para la búsqueda";

  constructor(
    private service: ServicioService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private autenticationService: AutenticacionService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {}

  ngOnInit() {
    this.cargarTipoEstablecimiento();
    this.changeCriterio("2");
    this.codigoEstablecimiento = this.producto.codEstablecimiento;
    this.catCodigoEstablecimiento = this.producto.catCodigoEstablecimiento;
    this.validarTipoPrecioV2();
    if (this.producto.action === "EDITAR") {
      this.cargarProducto();
      this.criterio = "2";
    }
    this.mostrarFormV2();
  }

  onFocused(e) {
    // do something when input is focused
  }

  cargarProducto(): void {
    this.filtro.codigoProducto = this.producto.codigoProducto;
    this.buscarPorCodigoProducto("EDITAR");
    this.entidad = {
      precio1: this.producto.precio1
        ? Number(this.producto.precio1).toFixed(2)
        : null,
      precio2: this.producto.precio2
        ? Number(this.producto.precio2).toFixed(2)
        : null,
      precio3: this.producto.precio3
        ? Number(this.producto.precio3).toFixed(2)
        : null,
      codProdE: this.producto.codigoProducto,
      tipoPrecio: this.tipoPrecio,
      fracciones: this.producto.fracciones,
      codEstablecimiento: this.producto.codEstablecimiento,
      catCodigoEstablecimiento: this.catCodigoEstablecimiento,
      title: this.producto.title,
    };

    (this.filtro.nombreProducto = this.producto.nombreProducto),
      (this.activeInput = true);
  }

  // NO SE USA
  validarTipoPrecio(): void {
    if (
      this.catCodigoEstablecimiento === "06" &&
      this.producto.codTipoEstablecimiento === "1"
    ) {
      // un precio
      this.tipoPrecio = 0;
    } else if (
      this.catCodigoEstablecimiento === "06" &&
      this.producto.codTipoEstablecimiento === "2"
    ) {
      this.tipoPrecio = 1;
    } else if (
      this.catCodigoEstablecimiento === "03" ||
      this.catCodigoEstablecimiento === "04" ||
      this.catCodigoEstablecimiento === "05"
    ) {
      // dos precios
      this.tipoPrecio = 1;
    } else if (
      this.catCodigoEstablecimiento === "01" ||
      this.catCodigoEstablecimiento === "02"
    ) {
      // tres precios
      this.tipoPrecio = 2;
    }
  }

  // reemplaza a validarTipoPrecio()
  public validarTipoPrecioV2(): void {
    switch (this.producto.codTipoEstablecimiento) {
      case Constante.ESTABLECIMIENTO_PUBLICO: {
        if (
          this.catCodigoEstablecimiento === "03" ||
          this.catCodigoEstablecimiento === "04" ||
          this.catCodigoEstablecimiento === "05" ||
          this.catCodigoEstablecimiento === "06"
        ) {
          this.tipoPrecio = Constante.UN_PRECIO;
        }
        if (
          this.catCodigoEstablecimiento === "01" ||
          this.catCodigoEstablecimiento === "02"
        ) {
          this.tipoPrecio = Constante.TRES_PRECIOS;
        }
        break;
      }
      case Constante.ESTABLECIMIENTO_PRIVADO: {
        if (
          this.catCodigoEstablecimiento === "03" ||
          this.catCodigoEstablecimiento === "04" ||
          this.catCodigoEstablecimiento === "05" ||
          this.catCodigoEstablecimiento === "06"
        ) {
          this.tipoPrecio = Constante.DOS_PRECIOS;
        }
        if (
          this.catCodigoEstablecimiento === "01" ||
          this.catCodigoEstablecimiento === "02"
        ) {
          this.tipoPrecio = Constante.TRES_PRECIOS;
        }
        break;
      }
      default: {
        // algo por default
      }
    }
  }

  public guardarPrecio(form?: any): void {
    if (this.validarProducto()) {
      this.showGeneralProgres = true;
      this.service
        .guardar("preciovista/guardar", "msopmcovid", this.setRequest())
        .pipe(finalize(() => (this.showGeneralProgres = false)))
        .subscribe(
          (response) => {
            if (response.codigo === Constante.RESPUESTA_OK) {
              this.toastr.success(
                response.mensaje,
                "Info ",
                this.utilitario.setOptionsToat()
              );
              this.close(true);
            } else {
              this.toastr.error(
                response.mensaje,
                "Error",
                this.utilitario.setOptionsToat()
              );
            }
          },
          (error: Error) => {
            this.toastr.error(
              Constante.MENSAJE_ERROR_CONEXION,
              "Error:",
              this.utilitario.setOptionsToat()
            );
          }
        );
    }
  }

  openModalConfirmar(): void {
    // abre modal para confirmar eliminación.
    const precioSugerido = this.getPrecioSugerido();

    const product = {
      title: "Confirmación",
      precioSugerido,
      mostrarSugerido:
        this.tipoPrecio !== 1 &&
        Number(precioSugerido) === Number(this.entidad.precio2)
          ? false
          : true,
      precio1: this.entidad.precio1,
      precio2: this.entidad.precio2,
      precio3: this.entidad.precio3,
      codigoProducto: this.producto.codProdE,
      tipoPrecio: this.tipoPrecio,
      fracciones: this.entidad.fracciones,
      action: this.producto.action,
      codEstablecimiento: this.producto.codEstablecimiento,
      catCodigoEstablecimiento: this.catCodigoEstablecimiento,
      nombreProducto: this.producto.nombreProducto,
    };
    const modalRef: NgbModalRef = this.modalService.open(
      ModalConfirmarComponent,
      { centered: true, size: "sm" }
    );
    modalRef.componentInstance.data = product;
    modalRef.componentInstance.closeMyModal = (response) => {
      modalRef.close();

      if (response) {
        this.guardarPrecio();
      }
    };
  }

  verModalConfirmar() {
    if (this.validarPrecio()) {
      const precioUnitarioSugerido = this.getPrecioSugerido();
      let mensaje =
        "Precio unitario sugerido: S/." +
        precioUnitarioSugerido +
        ", verifique precio empaque.";
      const data = {
        title: "Confirmación",
        mensaje: this.validarMensajeV2(mensaje, precioUnitarioSugerido),
        pregunta: "¿Está seguro de realizar la operación?",
      };
      const modalRef: NgbModalRef = this.modalService.open(
        ModalConfirmarSiNoComponent,
        { centered: true, size: "sm" }
      );
      modalRef.componentInstance.data = data;
      modalRef.componentInstance.closeMyModal = (response) => {
        modalRef.close();

        if (response) {
          this.entidad.validarProcesando = false;
          this.guardarPrecio();
        }
      };
    }
  }

  // NO SE USA... 030721
  validarMensaje(mensaje: string, precioUnitarioSugerido: number): string {
    let response = mensaje;
    if (
      (this.catCodigoEstablecimiento !== Constante.FARMACIA &&
        this.catCodigoEstablecimiento !== Constante.BOTICA &&
        this.catCodigoEstablecimiento !== Constante.BOTIQUIN) ||
      precioUnitarioSugerido === Number(this.entidad.precio2) // agregar para tipo 06 y privado
    ) {
      response = "";
    }
    if (
      this.catCodigoEstablecimiento === "06" &&
      this.producto.codTipoEstablecimiento === "2" &&
      precioUnitarioSugerido !== Number(this.entidad.precio2)
    ) {
      response = mensaje;
    }

    return response;
  }

  validarMensajeV2(mensaje: string, precioUnitarioSugerido: number): string {
    let response = "";
    if (this.tipoPrecio === Constante.DOS_PRECIOS) {
      if (precioUnitarioSugerido !== Number(this.entidad.precio2)) {
        response = mensaje;
      }
    }

    return response;
  }

  getPrecioSugerido(): number {
    let precioSugerido = (
      Number(this.entidad.precio1) / Number(this.entidad.fracciones)
    ).toFixed(2);

    return Number(precioSugerido);
  }

  public validarProducto(): boolean {
    if (
      this.entidad.codProdE !== undefined &&
      this.entidad.codProdE !== null &&
      this.entidad.codProdE !== ""
    ) {
      return true;
    } else {
      this.toastr.warning(
        "Seleccione un producto válido",
        "Validación",
        this.utilitario.setOptionsToat()
      );
      return false;
    }
  }

  private validarPrecio(): boolean {
    let response = true;

    switch (this.tipoPrecio) {
      case 0: {
        response = this.validarCeros(this.tipoPrecio);
        break;
      }
      case 1: {
        response = this.validarCeros(this.tipoPrecio);
        break;
      }
      case 2: {
        if (this.validarCeros(this.tipoPrecio)) {
          if (
            Number(this.entidad.precio3) > Number(this.entidad.precio1) ||
            Number(this.entidad.precio3) < Number(this.entidad.precio2)
          ) {
            this.toastr.warning(
              "El precio mediana debe ser mayor o igual al precio mínimo y menor o igual al precio máximo",
              "Validación",
              this.utilitario.setOptionsToat()
            );
            response = false;
          }
        } else {
          response = false;
        }
        break;
      }

      default:
    }

    return response;
  }

  private validarCeros(tipoPrecio: number): boolean {
    let response = true;
    switch (tipoPrecio) {
      case 0:
        {
          if (Number(this.entidad.precio1) === 0) {
            this.toastr.warning(
              Constante.PRECIO_MAYOR_CERO,
              Constante.VALIDATION,
              this.utilitario.setOptionsToat()
            );
            response = false;
          }
        }
        break;
      case 1:
        {
          if (
            Number(this.entidad.precio1) === 0 ||
            (Number(this.entidad.precio2) === 0 && !this.precioUnitarioReadOnly)
          ) {
            this.toastr.warning(
              Constante.PRECIO_MAYOR_CERO,
              Constante.VALIDATION,
              this.utilitario.setOptionsToat()
            );
            response = false;
          } else {
            if (this.precioUnitarioReadOnly) {
              this.entidad.precio2 = this.entidad.precio1;
            }
          }
        }
        break;
      case 2:
        {
          if (
            Number(this.entidad.precio1) === 0 ||
            Number(this.entidad.precio2) === 0 ||
            Number(this.entidad.precio3) === 0
          ) {
            this.toastr.warning(
              "Los precios registrados deben ser mayores a 0.00",
              "Validación",
              this.utilitario.setOptionsToat()
            );
            response = false;
          }
        }
        break;
    }
    return response;
  }

  public buscarProductos(proveedor: any): void {
    this.mostrarProductoFiltrado = false;
    let nombreProveedor = [];
    nombreProveedor.push(proveedor);

    if (proveedor.length >= 4) {
      this.service
        .listar(
          "producto/listar",
          "msopmcovid",
          this.setFiltro(proveedor, "tokenDefault")
        )
        .subscribe(
          (response) => {
            if (response.codigo === Constante.RESPUESTA_OK) {
              if (response.data !== null) {
                this.listaProductosFiltrados = response.data;
                this.mostrarProductoFiltrado = true;
              } else {
                if (this.toastr.toasts.length === 0) {
                  this.toastr.error(
                    Constante.PRODUCTO_NO_EXISTE_ADMINISTRADO,
                    Constante.ERROR,
                    this.utilitario.setOptionsToat()
                  );
                }
              }
            } else {
              this.listaProductosFiltrados = [];
              this.toastr.error(
                response.mensaje,
                Constante.ERROR,
                this.utilitario.setOptionsToat()
              );
            }
          },
          (e) => {
            //sessionStorage.removeItem('token');
          }
        );
    }
  }

  public buscarPorCodigoProducto(option: string): void {
    const codigo = this.filtro.codigoProducto;
    if (this.validarCodigo()) {
      this.showGeneralProgres = true;

      this.service
        .listar("producto/listar", "msopmcovid", this.setFiltro(null, "token"))
        .pipe(finalize(() => (this.showGeneralProgres = false)))
        .subscribe(
          (response) => {
            if (response.codigo === Constante.RESPUESTA_OK) {
              if (response.data !== null) {
                if (response.data.length > 0) {
                  this.seleccionarProducto(response.data[0], option);
                  this.filtro.codigoProducto = codigo;
                } else {
                  this.limpiar();
                  this.toastr.error(
                    Constante.PRODUCTO_NO_EXISTE_ADMINISTRADO,
                    Constante.ERROR,
                    this.utilitario.setOptionsToat()
                  );
                  this.filtro.codigoProducto = codigo;
                }
              } else {
                this.limpiar();
                this.toastr.error(
                  Constante.PRODUCTO_NO_EXISTE_ADMINISTRADO,
                  Constante.ERROR,
                  this.utilitario.setOptionsToat()
                );
                this.filtro.codigoProducto = codigo;
              }
            }
          },
          (e) => {
            console.log(e);
            //sessionStorage.removeItem('token');
          }
        );
    }
  }

  validarCodigo(): boolean {
    if (
      this.filtro.codigoProducto !== undefined &&
      this.filtro.codigoProducto !== null &&
      this.filtro.codigoProducto !== ""
    ) {
      return true;
    } else {
      this.toastr.warning(
        "Ingrese código de producto",
        Constante.VALIDATION,
        this.utilitario.setOptionsToat()
      );
      return false;
    }
  }

  public setFiltro(nombreProducto: any, tokenGoogle: string) {
    // retorna request para filtrar productos
    const nombreProduct = nombreProducto;
    return {
      filtro: {
        nombreProducto:
          nombreProduct !== "" && nombreProduct !== null ? nombreProduct : null,
        codProducto:
          this.filtro.codigoProducto !== "" &&
          this.filtro.codigoProducto !== null
            ? this.filtro.codigoProducto
            : null,
        pagina: 1,
        tamanio: 10,
        tokenGoogle,
      },
    };
  }

  public seleccionarProducto(item: any, option: string) {
    if (option === "NUEVO") {
      this.limpiar();
    }

    this.filtro.nombreProducto =
      item.codigoProducto +
      " - " +
      item.nombreProducto +
      " " +
      item.concent +
      " " +
      item.nombreFormaFarmaceutica +
      " " +
      item.fracciones +
      " unid. " +
      item.presentacion +
      " " +
      "RS:" +
      item.nroRegistroSanitario +
      " - " +
      item.titular;

    this.listaProductosFiltrados = [];
    this.mostrarProductoFiltrado = false;
    this.entidad.codProdE = item.codigoProducto;
    this.entidad.fracciones = item.fracciones;
    this.validarFraccionUno(item);
  }

  private validarFraccionUno(item: any) {
    if (Number(item.fracciones) === 1 && this.tipoPrecio === 1) {
      this.precioUnitarioReadOnly = true;
    }
  }

  limpiar(): void {
    this.listaProductosFiltrados = [];
    this.filtro = [];
    this.mostrarProductoFiltrado = false;
    this.entidad.precio1 = null;
    this.entidad.precio2 = null;
    this.entidad.precio3 = null;
    this.entidad.codProdE = null;
    this.precioUnitarioReadOnly = false;
    this.filtro.nombreProducto = null;
  }

  public cargarTipoEstablecimiento(): void {
    this.entidad.codigoTipoEstablecimiento = "";
    this.tipoEstablecimiento = [
      { codigo: "01", descripcion: "FARMACIAS, BOTICAS Y FARES" },
      {
        codigo: "02",
        descripcion: "DROGUERÍA/LABORATORIO - INFORMACIÓN DE STOCKS",
      },
      {
        codigo: "03",
        descripcion: "DROGUERÍA/LABORATORIO - INFORMACIÓN PRECIOS VENTA",
      },
    ];
    this.showGeneralProgres = false;
  }

  public close(response?: any): void {
    this.modalService.dismissAll(response);
  }

  // revisar esto
  public mostrarForms(): void {
    if (
      this.catCodigoEstablecimiento === Constante.LABORATORIO ||
      this.catCodigoEstablecimiento === Constante.DROGUERIA
    ) {
      this.tipoForm = "01"; // TRES PRECIOS
    } else if (
      this.catCodigoEstablecimiento === Constante.FARMACIA ||
      this.catCodigoEstablecimiento === Constante.BOTICA ||
      this.catCodigoEstablecimiento === Constante.BOTIQUIN
    ) {
      this.tipoForm = "02"; // DOS PRECIOS
    } else if (
      this.catCodigoEstablecimiento === Constante.SERVICIO_DE_FARMACIA &&
      this.producto.codTipoEstablecimiento === Constante.ESTABLECIMIENTO_PUBLICO
    ) {
      this.tipoForm = "03"; // UN PRECIO
    } else if (
      this.catCodigoEstablecimiento === Constante.SERVICIO_DE_FARMACIA &&
      this.producto.codTipoEstablecimiento === Constante.ESTABLECIMIENTO_PRIVADO
    ) {
      this.tipoForm = "02"; // DOS PRECIOS
    } else {
      this.tipoForm = null;
    }
  }

  public mostrarFormV2(): void {
    if (this.tipoPrecio === Constante.UN_PRECIO) {
      this.tipoForm = Constante.FORM_UN_PRECIO;
    }
    if (this.tipoPrecio === Constante.DOS_PRECIOS) {
      this.tipoForm = Constante.FORM_DOS_PRECIOS;
    }
    if (this.tipoPrecio === Constante.TRES_PRECIOS) {
      this.tipoForm = Constante.FORM_TRES_PRECIOS;
    }
  }

  public setRequest() {
    return {
      entidad: {
        codEstab: this.codigoEstablecimiento,
        codProdE: this.entidad.codProdE,
        precio1: this.entidad.precio1,
        precio2: this.entidad.precio2,
        precio3: this.entidad.precio3,
        tipoPrecio: this.tipoPrecio,
        tipoInsert: Constante.INSERT_VALIDO,
        fracciones: this.entidad.fracciones,
      },
      usuario: this.autenticationService.obtenerDatosUsuario().usuario,
    };
  }

  changeCriterio(codigo?: any): void {
    this.limpiar();
    if (codigo === "1") {
      this.activeInput = true;
    } else {
      this.activeInput = false;
    }
  }

  descargarCatalogoProductos(): void {
    this.showGeneralProgres = true;
    this.recaptchaV3Service.execute("catalogoproductos").subscribe((token) => {
      if (token) {
        this.service
          .descargarGeneric(
            "producto/catalogoproductos",
            "msopmcovid",
            this.setRequestCatalogo(token)
          )
          .pipe(finalize(() => (this.showGeneralProgres = false)))
          .subscribe(
            (response) => {
              var blob = new Blob([response], {type: "application/vnd.ms-excel"});
              saveAs(blob, "catalogoproductos" + Constante.EXTENSION_XLSX);
            },
            (error: Error) => {
              console.error(error);
              this.toastr.error(
                Constante.MENSAJE_ERROR_CONEXION,
                "Error:",
                this.utilitario.setOptionsToat()
              );
            }
          );
      }
    });
  }

  setRequestCatalogo(tokenGoogle: string) {
    return {
      filtro: {
        situacion: "ACT",
        tokenGoogle,
      },
    };
  }
}
