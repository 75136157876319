import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-recomendaciones',
  templateUrl: './modal-recomendaciones.component.html',
  styleUrls: ['./modal-recomendaciones.component.css']
})
export class ModalRecomendacionesComponent implements OnInit {

  //pdf contenido
  pdfSrc = 'COM_020-2023.pdf';

  meses = [
      { codigo: 1, descripcion: 'Enero' },
      { codigo: 2, descripcion: 'Febrero' },
      { codigo: 3, descripcion: 'Marzo' },
      { codigo: 4, descripcion: 'Abril' },
      { codigo: 5, descripcion: 'Mayo' },
      { codigo: 6, descripcion: 'Junio' },
      { codigo: 7, descripcion: 'Julio' },
      { codigo: 8, descripcion: 'Agosto' },
      { codigo: 9, descripcion: 'Septiembre' },
      { codigo: 10, descripcion: 'Octubre' },
      { codigo: 11, descripcion: 'Noviembre' },
      { codigo: 12, descripcion: 'Diciembre' },
    ];
  mesActual = "";
  finMes = "";
  fechaActual = null;
  anioActual = null;

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
    var fechaActual = new Date();
    this.anioActual = fechaActual.getFullYear();
    this.mesActual = this.meses[fechaActual.getMonth()].descripcion;
    this.finMes = (new Date(fechaActual.getFullYear(), fechaActual.getMonth() + 1, 0)).getDate().toString();
  }

  close(response: any): void {
    this.modalService.dismissAll(response);
  }

}
