export class Titulos {
  //COMO ELEGIR
  static PARA_LABORATORIOS_DROGUERIAS = "Para Laboratorios/Droguerías";
  static PARA_SECTOR_PRIVADO =
    "Servicio de Farmacia/Farmacias/Boticas del Sector Privado";
  static PARA_SECTOR_PUBLICO =
    "Servicio de Farmacia/Farmacias/Boticas del Sector Público";
  static TITULO_MODAL_REPROCESAR =
    "Reprocesar Observados por Correlación de Precios";
  static NOTA_MODAL_REPLICAR = "Al REPLICAR la plataforma solo mostrará:";
  static NOTA_MODAL_REPLICAR_ITEM01 =
    "Los productos cargados de la última carga de los dos últimos meses";
  static NOTA_MODAL_REPLICAR_ITEM02 =
    "Los productos cuya correlación de precios esté correcta, el resto de productos deberá volver a cargarlos";
  static NOTA_CARGA_MASIVA_SIZE_FILE =
    "La denominación del archivo no debe sobrepasar los 70 caracteres; se sugiere que solo contenga el RUC_CODEEFF_MES(número)_ AÑO(número)_ ABREVIATURA DEL TIPO DE PROCESO; en caso de tener varias sucursales no incluir el CODEEFF.";
  static NOTA_REPROCESAR_UPDATE_CORREO =
    "En caso de no ser actual el correo, actualizarlo en el Modulo de actualización de datos, cerrar sesión y volver a ingresar.";
  static NOTA_REPROCESAR_APLICA =
    "El reprocesar aplica para todos sus precios observados por correlación de precios en el archivo cargado.";
  static TEXTO_CONSTANCIA_INSCRIPCION =
    "Mediante esta funcionalidad podrá descargar la constancia de inscripción de los establecimientos farmacéuticos asociados al RUC.";
}
