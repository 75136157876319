import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';

import { ServicioApiService } from './servicio-api.service';
import { IResponseData } from './interface/IResponseData';
import { IRequest } from '../nucleo/interface/IRequest';
import { IModel } from '../nucleo/interface/IModel';
import { IAfiliacion } from '../nucleo/interface/IAfiliacion';
import { HttpResponse } from "@angular/common/http";


@Injectable()

export class ServicioService{

    constructor(private servidorApi: ServicioApiService){
    }

    listar(path: string, mservice: string, filtro: any): Observable<IResponseData> {
        return this.servidorApi.listar(path, mservice, filtro);
    }

    postGeneric(path: string, mservice: string, filtro: any): Observable<IResponseData> {
        return this.servidorApi.postGeneric(path, mservice, filtro);
    }

    descargarGeneric(path: string, mservice: string, object: object): Observable<Blob> {
        return this.servidorApi.descargarGeneric(path, mservice, object);
    }

    descargarConNombre(path: string, mservice: string, object: object): Observable<HttpResponse<Blob>> {
        console.log("Request enviado:", object);
        return this.servidorApi.descargarConNombre(path, mservice, object);
    }

    descargarConNombreCSV(path: string, mservice: string, object: object): Observable<HttpResponse<Blob>> {
        return this.servidorApi.descargarConNombreCSV(path, mservice, object);
    }
    

    afiliar(path: string, afiliacion: IModel): Observable<IResponseData> {
        return this.servidorApi.afiliar(path, afiliacion);
    }

    consultar(path: string, afiliacion: IModel): Observable<IResponseData> {
        return this.servidorApi.consultar(path, afiliacion);
    }

    activar(path: string, afiliacion: IAfiliacion): Observable<IResponseData> {
        return this.servidorApi.activar(path, afiliacion);
    }

    bloquear(path: string, afiliacion: IAfiliacion): Observable<IResponseData> {
        return this.servidorApi.bloquear(path, afiliacion);
    }

    desvincular(path: string, afiliacion: IAfiliacion): Observable<IResponseData> {
        return this.servidorApi.desvincular(path, afiliacion);
    }

    sincronizar(path: string, afiliacion: IAfiliacion): Observable<IResponseData> {
        return this.servidorApi.sincronizar(path, afiliacion);
    }

    obtenerData(path: string, request: IRequest): Observable<IResponseData> {
        return this.servidorApi.obtenerData(path, request);
    }

    guardar(path: string, mservice:string, request: any): Observable<any> {
        return this.servidorApi.guardar(path, mservice, request);
    }
}
