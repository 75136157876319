import { Component, OnInit } from "@angular/core";
import { finalize } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";

import { ModalGenericoService } from "./modal-generico.service";
import { ModalConfirmacionService } from "../modal-confirmacion/modal-confirmacion.service";
import { SettingService } from "../../nucleo/configuracion/setting.service";
import { Constante } from "../../nucleo/constante/Constante";
import { IModalGenerico } from "../../nucleo/interface/IModalGenerico";
import { ServicioService } from "../../servicio/servicio.service";
import { ICombo } from "../../nucleo/interface/ICombo";

@Component({
  selector: "app-modal-generico",
  templateUrl: "./modal-generico.component.html",
  styleUrls: ["./modal-generico.component.css"],
})
export class ModalGenericoComponent implements OnInit {
  public modal: IModalGenerico;
  private codigoMensaje: string;
  private titulo: string;
  public showGeneralProgres: boolean;
  public listTipoBloqueo: ICombo;
  // variables locales para ejemplos de Constante.EXTENSION_XLSXx
  formatosA = [];
  formatosB = [];
  formatosC = [];
  formatos = [];
  constructor(
    public modalGService: ModalGenericoService,
    private modalCService: ModalConfirmacionService,
    private settingService: SettingService,
    private service: ServicioService,
    public toastr: ToastrService
  ) {
    this.modal = {
      tipoBloqueo: "",
      valorSeguridad: "",
      valorSeguridadSgte: "",
    };
    modalGService.registrarComponente(this);
  }

  ngOnInit() {
    this.cargarFormatos();
  }

  cargarFormatos(): void {
    this.formatosA = [
      {
        codigo: "CodEstab",
        descripcion: "Código de Establecimiento Farmacéutico.",
      },
      { codigo: "CodProdG", descripcion: "Código genérico del Producto." },
      { codigo: "CodProdE", descripcion: "Código específico del Producto." },
      {
        codigo: "Preciov",
        descripcion:
          "Precio de venta al público de una unidad mínima de presentación.",
      },
      {
        codigo: "Stock",
        descripcion:
          "Número de unidades mínimas de presentación del bien disponibles fisicamente.",
      },
    ];

    this.formatosB = [
      {
        codigo: "CodEstab",
        descripcion: "Código de Establecimiento Farmacéutico.",
      },
      { codigo: "CodProdE", descripcion: "Código específico del Producto" },
      {
        codigo: "Stock",
        descripcion:
          "Número de unidades mínimas de presentación del bien disponibles fisicamente.",
      },
      {
        codigo: "Uni_imp",
        descripcion:
          "Número de unidades mínimas de presentación del bien importados.",
      },
      {
        codigo: "Uni_fab",
        descripcion:
          "Número de unidades mínimas de presentación del bien fabricados",
      },
    ];
    this.formatosC = [
      {
        codigo: "CodEstab",
        descripcion: "Código de Establecimiento Farmacéutico.",
      },
      { codigo: "CodProdE", descripcion: "Código específico del Producto" },
      {
        codigo: "Seg",
        descripcion:
          "1) institucion publica; 2) cadenas; 3)independientes; 4)droguerias; 5)otros no farmaceuticos",
      },
      {
        codigo: "PrecioMin",
        descripcion: "Precio Mínimo de la unidad mínima de presentación.",
      },
      {
        codigo: "PrecioMax",
        descripcion: "Precio Máximo de la unidad mínima de presentación.",
      },
      {
        codigo: "PrecioMed",
        descripcion: "Precio Mediana de la unidad mínima de presentación.",
      },
      {
        codigo: "Preciov",
        descripcion:
          "Precio de venta al público de una unidad mínima de presentación",
      },
    ];
  }

  cerrarModal() {
    this.modalGService.ocultarModal();
    this.modalGService.notificacion.emit({ notificacion: false });
  }

  aceptar() {
    switch (this.modalGService.accion) {
      case "BLOQUEAR":
        this.codigoMensaje = "C0019";
        this.titulo = Constante.TITULO_BLOQUEAR;
        break;
      case "SINCRONIZAR":
        this.codigoMensaje = "C0060";
        this.titulo = Constante.TITULO_SINCRONIZAR;
        break;
    }

    this.modalCService
      .mostrarModal(
        this.settingService.getMensaje(this.codigoMensaje),
        this.titulo
      )
      .then((response) => {
        if (response === true) {
          this.modalGService.ocultarModal();
          this.modal.notificacion = true;
          this.modalGService.notificacion.emit(this.modal);
        }
      });
  }

  cargarCombo() {
    this.service
      .obtenerData("valorsistema", { filtro: { tipo: "M", usuario: "carlos" } })
      .pipe(finalize(() => (this.showGeneralProgres = false)))
      .subscribe(
        (response) => {
          if (response.codigo === Constante.RESPUESTA_OK) {
            this.listTipoBloqueo = response.data;
          } else {
            this.toastr.warning(
              "CodigoMensaje:" + response.mensaje,
              response.codigo,
              this.setOptionsToat()
            );
          }
        },
        (error: Error) => {
          console.log(error.message);
          this.toastr.error(error.message, "Error:", this.setOptionsToat());
        }
      );
  }

  limpiarCampos() {}

  setOptionsToat() {
    return {
      timeOut: 5000,
      progressBar: true,
      closeButton: true,
    };
  }
}
