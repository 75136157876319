import { Routes, RouterModule } from '@angular/router';
import { AdminLayoutComponent } from '../layouts/layout.component';
import { LoginComponent } from '../authentication/login/login.component';
//import { ChangePasswordComponent } from '../authentication/change-password/change-password.component';
import { RecuperaPassComponent } from '../authentication/recupera-pass/recupera-pass.component';
import { ModuleWithProviders } from '@angular/core';
import { AuthorizationGuard } from '../nucleo/guards/auth-guards';
// tslint:disable-next-line: max-line-length
import { ConsultaProductosListarComponent } from '../publico/consulta-productos/consulta-productos-listar/consulta-productos-listar.component';
import { ConsultaProductosGenericosComponent } from '../publico/consulta-productos/consulta-productos-genericos/consulta-productos-genericos.component';

export const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        loadChildren: './home/home.module#HomeModule',
      },
      {
        path: 'afiliacion',
        loadChildren: './afiliacion/afiliacion.module#AfiliacionModule',
      },
      {
        path: 'precio-productos',
        loadChildren:
          './precio-productos/precio-productos.module#PrecioProductosModule',
      },
      {
        path: 'precio-covid',
        loadChildren:
          './precio-covid/precio-covid.module#PrecioCovidModule',
      },
      {
        path: 'solicitud',
        loadChildren:
          './solicitud/solicitud.module#SolicitudModule',
      },
      {
        path: 'reportes',
        loadChildren: './reportes/reportes.module#ResportesModule',
      },
      {
        path: 'atencion-consultas',
        loadChildren: './atencion-consultas/atencion-consultas.module#AtencionConsultasModule',
      },
      {
        path: 'configuracion',
        loadChildren:
          './configuracion/configuracion.module#ConfiguracionModule',
      },
    ],
    canActivate: [AuthorizationGuard],
  },

  /* {
  path: 'consulta-producto', 
  component: ConsultaProductosListarComponent, 
  children: [
    {
      path: 'consulta-producto',  loadChildren: './publico/consulta-productos/consulta-productos.module#ConsutaProductosModule',
    }
  ]
}, */
  {
    path: 'consulta-producto',
    component: ConsultaProductosListarComponent,
    data: {
      breadcrumb: 'Consultar productos',
      status: true,
    },
  },
  {
    path: 'consulta-genericos',
    component: ConsultaProductosGenericosComponent,
    data: {
        breadcrumb: 'Consultar genericos',
        status: true
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      breadcrumb: 'Iniciar sesión',
      status: true,
    },
  },
  /*
  {
    path: 'changePassword',
    component: ChangePasswordComponent,
  },
  */
  {
    path: 'recupera-pass',
    component: RecuperaPassComponent,
    data: {
      breadcrumb: 'Recuperar Contraseña',
      status: true,
    },
  },
  /*
  {
 path: '',
component: LoginComponent,
children: [
    {
     path: 'authentication',
     loadChildren: './authentication/authentication.module#AuthenticationModule'
    }
  ]
},  */
  {
    path: '**',
    redirectTo: 'consulta-producto',
  },
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
  useHash: true,
});
