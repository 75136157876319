import { Component, Input, OnInit } from "@angular/core";
import { Constante } from "../../nucleo/constante/Constante";

@Component({
  selector: "app-modal-reprocesar",
  templateUrl: "./modal-reprocesar.component.html",
  styleUrls: ["./modal-reprocesar.component.css"],
})
export class ModalReprocesarComponent implements OnInit {
  @Input() public data: any;
  @Input() closeMyModal: (any) => void;
  textoReprocesar: string = Constante.TEXTO_MODAL_REPROCESAR;

  constructor() {}

  ngOnInit() {}

  close(valor: boolean): void {
    const response = {
      valor: valor,
      contrasenia: this.data.contrasenia,
    };
    this.closeMyModal(response);
  }
}
