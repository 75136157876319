import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ClickOutsideModule } from "ng-click-outside";
import { ToastrModule } from "ngx-toastr";

import { ModalConfirmacionComponent } from "../components/modal-confirmacion/modal-confirmacion.component";
import {
  AccordionAnchorDirective,
  AccordionLinkDirective,
  AccordionDirective,
} from "./accordion";
import { ModalGenericoComponent } from "../components/modal-generico/modal-generico.component";
import { ToggleFullscreenDirective } from "./fullscreen/toggle-fullscreen.directive";
import { SortColumnDirective } from "../nucleo/directiva/sort-column.directive";
import { ServicioApiService } from "../servicio/servicio-api.service";
import { CardRefreshDirective } from "./card/card-refresh.directive";
import { CardToggleDirective } from "./card/card-toggle.directive";
import { SpinnerComponent } from "../spinner/spinner.component";
import { ServicioService } from "../servicio/servicio.service";
import { CardComponent } from "./card/card.component";
import { MenuItems } from "./menu-items/menu-items";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ModalProductoDetalleComponent } from "../components/modal-producto-detalle/modal-producto-detalle.component";
import { ModalEnviarCorreoComponent } from "../components/modal-enviar-correo/modal-enviar-correo.component";
import { ModalPrecioProductoComponent } from "../components/modal-precio-producto/modal-precio-producto.component";
import { ModalPrecioNuevoComponent } from "../components/modal-precio-nuevo/modal-precio-nuevo.component";
import { ModalConfirmarComponent } from "../components/modal-confirmar/modal-confirmar.component";
import { ModalComoElegirComponent } from "../components/modal-como-elegir/modal-como-elegir.component";
import { ModalEditarObservadoComponent } from "../components/modal-editar-observado/modal-editar-observado.component";
import { NumericDirective } from "../nucleo/directiva/numeric-directive";
import { EmailValidator } from "../nucleo/directiva/email-directive";
import { ModalConfirmarSiNoComponent } from "../components/modal-confirmar-si-no/modal-confirmar-si-no.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ModalInformativoInicioComponent } from "../components/modal-informativo-inicio/modal-informativo-inicio.component";
import { ModalSuspensionComponent } from "../components/modal-suspension/modal-suspension.component";
import { ModalComunicacionComponent } from "../components/modal-comunicacion/modal-comunicacion.component";
import { NgbdSortableHeader } from "../nucleo/directiva/filter-table-directive";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { OrderModule } from "ngx-order-pipe";
import { ModalReprocesarComponent } from "../components/modal-reprocesar/modal-reprocesar.component";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient } from "@angular/common/http";
import { ModalContactoComponent } from "../components/modal-contacto/modal-contacto.component";
import { ModalRecomendacionesComponent } from "../components/modal-recomendaciones/modal-recomendaciones.component";
import { PdfViewerModule } from 'ng2-pdf-viewer';

export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    NgbModule,
    AutocompleteLibModule,
    ToastrModule.forRoot(),
    OrderModule,
    PdfViewerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ToggleFullscreenDirective,
    CardRefreshDirective,
    CardToggleDirective,
    CardComponent,
    SpinnerComponent,
    ModalConfirmacionComponent,
    ModalGenericoComponent,
    SortColumnDirective,
    ModalProductoDetalleComponent,
    ModalEnviarCorreoComponent,
    ModalPrecioProductoComponent,
    ModalPrecioNuevoComponent,
    ModalConfirmarComponent,
    ModalComoElegirComponent,
    ModalEditarObservadoComponent,
    ModalConfirmarSiNoComponent,
    ModalInformativoInicioComponent,
    ModalSuspensionComponent,
    ModalComunicacionComponent,
    ModalContactoComponent,
    NumericDirective,
    EmailValidator,
    NgbdSortableHeader,
    ModalReprocesarComponent,
	ModalRecomendacionesComponent
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ToggleFullscreenDirective,
    CardRefreshDirective,
    CardToggleDirective,
    AutocompleteLibModule,
    CardComponent,
    SpinnerComponent,
    FormsModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    ToastrModule,
    ModalConfirmacionComponent,
    ModalGenericoComponent,
    SortColumnDirective,
    ModalProductoDetalleComponent,
    ModalEnviarCorreoComponent,
    ModalPrecioProductoComponent,
    ModalPrecioNuevoComponent,
    ModalConfirmarComponent,
    ModalConfirmarSiNoComponent,
    ModalComoElegirComponent,
    ModalEditarObservadoComponent,
    ModalInformativoInicioComponent,
    ModalSuspensionComponent,
    ModalComunicacionComponent,
    ModalReprocesarComponent,
    ModalContactoComponent,
    NgbModule,
    NumericDirective,
    EmailValidator,
    NgbdSortableHeader,
    OrderModule,
    TranslateModule,
	ModalRecomendacionesComponent
  ],
  providers: [MenuItems, ServicioApiService, ServicioService],
})
export class SharedModule {}
