export class Utilitario {
  constructor() {}

  convertirFechaStringToObject(fecha: string): object {
    let arrayFecha: Array<string>;
    if (fecha !== null && fecha !== undefined) {
      arrayFecha = fecha.split("/");
      return {
        date: {
          year: Number(arrayFecha[2]),
          month: Number(arrayFecha[1]),
          day: Number(arrayFecha[0]),
        },
        formatted:
          Number(arrayFecha[0]) +
          "/" +
          Number(arrayFecha[1]) +
          "/" +
          Number(arrayFecha[2]),
      };
    }
  }

  convertirFechaObjectToString(objeto: any): string {
    return `${String("0" + objeto.date.day).slice(-2)}/${String(
      "0" + objeto.date.month
    ).slice(-2)}/${objeto.date.year}`;
  }

  convertirFechaToStringServicio(objeto: any): string {
    if (objeto !== undefined && objeto !== null) {
      return (
        objeto.date.year * 10000 + objeto.date.month * 100 + objeto.date.day
      );
    }
    return "";
  }

  getTime(time: any): string {
    if (time !== undefined && time !== "") {
      let hora = time.hour.toString();
      let minuto = time.minute.toString();
      if (time.hour < 10) {
        hora = "0" + time.hour.toString();
      }
      if (time.minute < 10) {
        minuto = "0" + time.minute.toString();
      }
      return hora + ":" + minuto + ":00";
    } else {
      return "";
    }
  }

  /*   isVisibleElement(permisoName: string): boolean {
    const result = this.authorizationService.authorize(true, permisoName);
    if (result !== AUTHORISED) {
      return false;
    }
    return true;
  } */

  getDescripcionCombo(codigo: string, data: any): string {
    if (codigo !== "") {
      const elemento = data.find((element) => element.codigo === codigo);
      return elemento.descripcion;
    } else {
      return "TODOS";
    }
  }

  setOptionsToat() {
    return {
      timeOut: 5000,
      progressBar: true,
      closeButton: true,
      maxOpened: 1,
      // positionClass: 'toast-center-center'
    };
  }
}
