import { Component, OnInit, Input } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ServicioService } from "../../servicio/servicio.service";
import { Constante } from "../../nucleo/constante/Constante";
import { saveAs } from "file-saver";
import { ToastrService } from "ngx-toastr";
import { Utilitario } from "../../nucleo/util/utilitario";
import { Titulos } from "../../nucleo/constante/Titulos";
import * as moment from "moment";

@Component({
  selector: "app-modal-como-elegir",
  templateUrl: "./modal-como-elegir.component.html",
  styleUrls: ["./modal-como-elegir.component.css"],
})
export class ModalComoElegirComponent implements OnInit {
  @Input() public data: any;
  entidad: any = {};
  currentOrientation = "vertical";
  utilitario = new Utilitario();
  textoLaboratoriosDroguerias = Titulos.PARA_LABORATORIOS_DROGUERIAS;
  textoSectorPrivado = Titulos.PARA_SECTOR_PRIVADO;
  textoSectorPublico = Titulos.PARA_SECTOR_PUBLICO;
  constructor(
    private modalService: NgbModal,
    private servicio: ServicioService,
    public toastr: ToastrService
  ) {}

  ngOnInit() {}

  close(response: any): void {
    this.modalService.dismissAll();
  }

  descargaExample(tipoEnvio: string): void {
    this.servicio
      .descargarGeneric(
        Constante.LOTE + "/csv",
        Constante.OPMCOVID,
        this.setRequest(tipoEnvio)
      )
      .subscribe(
        (response) => {
          saveAs(
            response,
            "excelFileExample_" + moment().format() + Constante.EXTENSION_XLSX
          );
        },
        (error: Error) => {
          console.error(error);
          this.toastr.error(
            Constante.MENSAJE_ERROR_CONEXION,
            "Error:",
            this.utilitario.setOptionsToat()
          );
        }
      );
  }

  setRequest(tipo: string) {
    return {
      filtro: {
        tipoEnvio: tipo,
      },
    };
  }
}
