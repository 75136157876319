import { Component, OnInit, Input } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ServicioService } from "../../servicio/servicio.service";
import { Constante } from "../../nucleo/constante/Constante";
import { ToastrService } from "ngx-toastr";
import { Utilitario } from "../../nucleo/util/utilitario";
import { finalize } from "rxjs/operators";
import { ReCaptchaV3Service } from "ng-recaptcha";

@Component({
  selector: "app-modal-producto-detalle",
  templateUrl: "./modal-producto-detalle.component.html",
  styleUrls: ["./modal-producto-detalle.component.css"],
})
export class ModalProductoDetalleComponent implements OnInit {
  closeResult: string;
  entidad: any = {};
  establecimiento: any = {};
  utilitario = new Utilitario();
  showGeneralProgres = false;
  @Input() public producto;
  constructor(
    private modalService: NgbModal,
    private servicio: ServicioService,
    private toastr: ToastrService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {}

  ngOnInit() {
    this.getDetalleProducto();
  }

  openVerticallyCentered(content) {
    this.modalService.open(content, { centered: true });
  }

  close(): void {
    this.modalService.dismissAll();
  }

  public getDetalleProducto(): void {
    this.showGeneralProgres = true;
    this.recaptchaV3Service
      .execute("productoDetalleCiudadano")
      .subscribe((token) => {
        if (token) {
          this.servicio
            .postGeneric(
              "precioproducto/obtener",
              "msopmcovid",
              this.setRequestProductoDetalle(this.producto, token)
            )
            .pipe(finalize(() => (this.showGeneralProgres = false)))
            .subscribe(
              (response) => {
                if (response.codigo === Constante.RESPUESTA_OK) {
                  this.entidad = response.entidad;
                  this.entidad.nombreProducto =
                    this.entidad.nombreProducto +
                    " " +
                    this.producto.concent +
                    " " +
                    this.producto.nombreFormaFarmaceutica;
                  this.entidad.presentacion =
                    this.entidad.presentacion +
                    " x " +
                    this.producto.fracciones +
                    " unid.";
                  //this.entidad.precio1 = this.producto.precio1; // cancelar
                  if (
                    this.producto.setcodigo === "Público" ||
                    this.producto.isSectorPublico
                  ) {
                    this.entidad.precio2 = this.entidad.precio1;
                    this.entidad.precio1 = null;
                  }

                  // this.establecimiento = response.entidad.establecimientoForm;
                } else {
                  this.toastr.error(
                    response.mensaje,
                    "Error",
                    this.utilitario.setOptionsToat()
                  );
                }
              },
              (error: Error) =>
                this.toastr.error(
                  Constante.MENSAJE_ERROR_CONEXION,
                  "Error",
                  this.utilitario.setOptionsToat()
                )
            );
        }
      });
  }

  setRequestProductoDetalle(producto: any, tokenGoogle: string) {
    return {
      filtro: {
        codigoProducto: producto.codigoProducto,
        codEstablecimiento: producto.codEstablecimiento,
        tokenGoogle,
      },
    };
  }
}
