import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  SimpleChanges,
  ViewEncapsulation,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { CommonComponent } from "../../../common/common.component";
import { ServicioService } from "../../../servicio/servicio.service";
import { ToastrService } from "ngx-toastr";
import { SettingService } from "../../../nucleo/configuracion/setting.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Constante } from "../../../nucleo/constante/Constante";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ModalProductoDetalleComponent } from "../../../components/modal-producto-detalle/modal-producto-detalle.component";
import { finalize } from "rxjs/operators";
import { saveAs } from "file-saver";
import { Utilitario } from "../../../nucleo/util/utilitario";
import {
  state,
  style,
  transition,
  animate,
  trigger,
  AUTO_STYLE,
} from '@angular/animations';
import { ModalInformativoInicioComponent } from "../../../components/modal-informativo-inicio/modal-informativo-inicio.component";
import { __await } from "tslib";
import { forEach } from "@angular/router/src/utils/collection";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { TranslateService } from "@ngx-translate/core";
import { NgSelectConfig } from "@ng-select/ng-select";

const compare = (v1: string | number, v2: string | number) =>
  v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

@Component({
  selector: "app-consulta-productos-genericos",
  templateUrl: "./consulta-productos-genericos.component.html",
  styleUrls: ["./consulta-productos-genericos.component.css"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("mobileMenuTop", [
      state(
        "no-block, void",
        style({
          overflow: "hidden",
          height: "0px",
        })
      ),
      state(
        "yes-block",
        style({
          height: AUTO_STYLE,
        })
      ),
      transition("no-block <=> yes-block", [animate("400ms ease-in-out")]),
    ]),
  ],
})
export class ConsultaProductosGenericosComponent
  extends CommonComponent
  implements OnInit {
  keyword = "nombreProducto";
  @ViewChild("auto") auto;
  catEstablecimientos = [];
  filtro: any = {};
  productos = [];
  page: number;
  pageSize: number;
  collectionSize = this.productos.length;
  departamentos = [];
  provincias = [];
  distritos = [];
  tipoEstablecimientos = [];
  verFiltros = false;
  checked = false;
  labelFiltros: string;
  listaProductosFiltrados = [];
  mostrarProductoFiltrado = false;
  showGeneralProgres = false;
  utilitario = new Utilitario();
  precioMinimo: any;
  isCollapsedMobile = "no-block";
  isCollapsedSideBar = "no-block";
  existePrecioMinimo = false;
  iniciaSelectDefault = true; // flag para cargar select por defecto LIMA LIMA
  existeData = false;
  labelTotal = "Total de Productos";
  labelPreciosReferenciales =
    '“Los precios de venta al público podrían diferir del ofertado al momento de la compra, se recomienda ver la opción "Ver detalle" y comunicarse previamente con el establecimiento."';
  labelDatosObtenidos =
    '"Los datos obtenidos en la consulta son en línea y reflejan la información en el tiempo hora/minuto que fue consultado, pudiendo refrescar la consulta utilizando el ícono"';
  systemTitle = Constante.SYSTEM_TITLE;
  logoMinsa = Constante.LOGO_MINSA;
  logoTelesalud = Constante.LOGO_TELESALUD;
  fondoOPM = Constante.FONDO_OPM;
  tituloOPM = Constante.TITULO_OPM;
  fondoOPMMovil = Constante.FONDO_OPM_MOVIL;
  tituloOPMMovil = Constante.TITULO_OPM_MOVIL;
  mostrarTableVacia = false;
  prescrito = false;
  nombreProducto = "";

  /*amontoya cambiar cars por productos*/
  selecGenerico: "";
    
  idiomas = [
    { value: "es", description: "Español" },
    // { value: "qu", description: "Quechua" }, 
    // { value: "ay", description: "Aymara" }, 
    { value: "en", description: "Inglés" },
    // { value: "pt", description: "Portugués" },
    /*    { value: "fr", description: "Francés" }, */
  ];
  constructor(
    servicio: ServicioService,
    toastr: ToastrService,
    private modalService: NgbModal,

    public settingService: SettingService,
    public router: Router,
    private rutaActual: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service,
    public translate: TranslateService,
    private selectConfig: NgSelectConfig
  ) {
    super(servicio, toastr);
    //this.openModalInformativo();
    this.setConfigLanguaje();
    this.selectConfig.notFoundText = '';
  }
  

  setConfigLanguaje() {
    this.translate.addLangs(['en', 'fr', 'es', 'qu']);
    this.translate.setDefaultLang('es');
    const browserLang = this.translate.getBrowserLang();
    localStorage.setItem('languaje', browserLang);
    this.translate.use(browserLang.match(/en|fr|qu|es/) ? browserLang : "es");
  }

  ngOnInit() {
    this.getProductosEsenciales();
    this.getDepartamentos();
    this.init();
  }

  selectEvent(item) {
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  setLanguaje(value: any) {
    this.translate.use(value);
    localStorage.setItem('languaje', value);
  }
  closePanel(e): void {
    e.stopPropagation();
    this.auto.close();
  }

  onFocused(e) {
    // do something when input is focused
  }

  public init(): void {
    this.cargarTipoEstablecimiento();
    this.getCatEstablecimiento();
    this.filtro = {
      codigoProducto: null,
      codigoTipoEstablecimiento: "",
      catEstablecimiento: "",
      codigoDepartamento: "",
      codigoProvincia: "",
      codigoDistrito: "",
    };

    this.page = 1;
    this.pageSize = 10;
    this.labelFiltros = 'Ver filtros';
  }

  iniciarSesion(): void {
    this.router.navigate(['../login'], { relativeTo: this.rutaActual });
  }

  consultaCiudadano(): void {
    this.router.navigate(['../consulta-producto'], { relativeTo: this.rutaActual });
  }

  cargarTipoEstablecimiento(): void {
    this.tipoEstablecimientos = [
      { codigo: 1, descripcion: "PÚBLICOS" },
      { codigo: 2, descripcion: "PRIVADOS" },
    ];
  }

  checkValue(event: any) {
    if (event === "A") {
      this.verFiltros = true;
      this.labelFiltros = "Ocultar filtros";
    } else {
      this.verFiltros = false;
      this.labelFiltros = "Ver filtros";
    }
    this.filtro.codigoDepartamento = "";
  }

  refrescar(): void {
    this.limpiar();
    this.init();
    //this.listarPrecioProductos();
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.nombreProducto.toLocaleLowerCase().indexOf(term) > -1 || 
    item.concent.toLocaleLowerCase().indexOf(term) > -1 || 
    item.nombreFormaFarmaceutica.toLocaleLowerCase().indexOf(term) > -1;
  }

  // WITH CAPTCHA
  listarPrecioProductos(tokenGoogle: string): void {
    if (this.validarProductoExistenteParaDescargaExcel()) {
      this.showGeneralProgres = true;
      this.service
        .listar(
          "preciovista/ciudadano",
          "msopmcovid",
          this.setRequest(tokenGoogle)
        )
        .pipe(
          finalize(() => {
            this.showGeneralProgres = false;
            this.iniciaSelectDefault = false;
          })
        )
        .subscribe(
          (response) => {
            if (response.codigo === Constante.RESPUESTA_OK) {
              if (response.data.length > 0) {
                this.validarSiMuestraPrecioMinimo(response);
                this.productos = response.data;
                this.validarPrecioUnitario(this.productos);
                this.collectionSize = response.cantidad;
                this.existeData = true;
                this.mostrarTableVacia = false;
              } else {
                this.toastr.info(
                  Constante.SIN_DATA,
                  "Info",
                  this.setOptionsToat()
                );
                this.productos = [];
                this.collectionSize = 0;
                this.existePrecioMinimo = false;
                this.existeData = false;
                this.mostrarTableVacia = true;
              }
            } else {
              this.toastr.error(
                response.mensaje,
                "Error",
                this.setOptionsToat()
              );
            }
          },
          (error: Error) =>
            this.toastr.error(
              Constante.MENSAJE_ERROR_CONEXION,
              "Error",
              this.setOptionsToat()
            )
        );
    }
  }

  /*  listarPrecioProductos(): void {
    if (this.validarProductoExistenteParaDescargaExcel()) {
      this.showGeneralProgres = true;
      this.service
        .listar("preciovista/ciudadano", "msopmcovid", this.setRequest())
        .pipe(
          finalize(() => {
            this.showGeneralProgres = false;
            this.iniciaSelectDefault = false;
          })
        )
        .subscribe(
          (response) => {
            if (response.codigo === Constante.RESPUESTA_OK) {
              if (response.data.length > 0) {
                this.validarSiMuestraPrecioMinimo(response);
                this.productos = response.data;
                this.validarPrecioUnitario(this.productos);
                this.collectionSize = response.cantidad;
                this.existeData = true;
                this.mostrarTableVacia = false;
              } else {
                this.toastr.info(
                  Constante.SIN_DATA,
                  "Info",
                  this.setOptionsToat()
                );
                this.productos = [];
                this.collectionSize = 0;
                this.existePrecioMinimo = false;
                this.existeData = false;
                this.mostrarTableVacia = true;
              }
            } else {
              this.toastr.error(
                response.mensaje,
                "Error",
                this.setOptionsToat()
              );
            }
          },
          (error: Error) =>
            this.toastr.error(
              Constante.MENSAJE_ERROR_CONEXION,
              "Error",
              this.setOptionsToat()
            )
        );
    }
  } */

  validarPrecioUnitario(productos: any) {
    productos.forEach((element) => {
      if (element.precio2 === null) {
        element.precio2 = element.precio1;
        element.precio1 = null;
      }
    });
  }

  validarSiMuestraPrecioMinimo(response: any): void {
    if (
      this.filtro.codigoProducto !== null &&
      this.filtro.codigoProducto !== ""
    ) {
      if (response.entidad != null) {
        this.precioMinimo = response.entidad;
        this.existePrecioMinimo = true;
      } else {
        this.existePrecioMinimo = false;
      }
    }
  }

  setRequest(tokenGoogle?: string) {
    return {
      filtro: {
        codigoProducto: this.filtro.codigoProducto,
        codigoDepartamento:
          this.filtro.codigoDepartamento !== null &&
          this.filtro.codigoDepartamento !== ""
            ? this.filtro.codigoDepartamento
            : null,
        codigoProvincia:
          this.filtro.codigoProvincia !== null &&
          this.filtro.codigoProvincia !== ""
            ? this.filtro.codigoProvincia
            : null,
        codigoUbigeo:
          this.filtro.codigoDistrito !== null &&
          this.filtro.codigoDistrito !== ""
            ? this.filtro.codigoDistrito
            : null,
        codTipoEstablecimiento:
          this.filtro.codigoTipoEstablecimiento !== null &&
          this.filtro.codigoTipoEstablecimiento !== ""
            ? this.filtro.codigoTipoEstablecimiento
            : null,
        catEstablecimiento:
          this.filtro.catEstablecimiento !== null &&
          this.filtro.catEstablecimiento !== ""
            ? this.filtro.catEstablecimiento
            : null,
        nombreEstablecimiento:
          this.filtro.nombreEstablecimiento !== null &&
          this.filtro.nombreEstablecimiento !== ""
            ? this.filtro.nombreEstablecimiento
            : null,
        nombreLaboratorio:
          this.filtro.nombreLaboratorio !== null &&
          this.filtro.nombreLaboratorio !== ""
            ? this.filtro.nombreLaboratorio
            : null,
        codGrupoFF: this.filtro.codGrupoFF,
        concent: this.filtro.concent,
        tamanio: this.pageSize,
        pagina: this.page,
        // WITH CAPTCHA - DESCOMENTAR
        tokenGoogle,
        nombreProducto: this.prescrito ? this.nombreProducto : null,
      },
    };
  }

  public getProductosEsenciales(): void {
    
    this.service
      .postGeneric(
        "producto/esenciales",
        "msopmcovid",
        {}
      )
      .pipe()
      .subscribe((response) => {
        if (response.codigo === Constante.RESPUESTA_OK) {
          if (response.data !== null) {
            this.listaProductosFiltrados = response.data;
            this.mostrarProductoFiltrado = true;
          } 
        } else {
          this.listaProductosFiltrados = [];
          this.toastr.error(
            response.mensaje,
            "Error:",
            this.utilitario.setOptionsToat()
          );
        }
      });
      //this.listaProductosFiltrados = this.cars;
      //this.mostrarProductoFiltrado = true;
  }

  public buscarProductos(proveedor: any): void {
    this.mostrarProductoFiltrado = false;
    let nombreProveedor = [];
    nombreProveedor.push(proveedor);

    if (proveedor.length > 4) {
      this.service
        .listar(
          "producto/autocompleteciudadano",
          "msopmcovid",
          this.setFiltroP("", proveedor)
        )
        .subscribe(
          (response) => {
            if (response.codigo === Constante.RESPUESTA_OK) {
              if (response.data !== null) {
                this.listaProductosFiltrados = response.data;
                this.mostrarProductoFiltrado = true;
              } else {
                if (this.toastr.toasts.length === 0) {
                  this.toastr.warning(
                    Constante.PRODUCTO_NO_EXISTE,
                    "Atención:",
                    this.utilitario.setOptionsToat()
                  );
                }
              }
            } else {
              this.listaProductosFiltrados = [];
              this.toastr.error(
                response.mensaje,
                "Error:",
                this.utilitario.setOptionsToat()
              );
            }
          },
          (e) => {
            if (this.toastr.toasts.length === 0) {
              this.toastr.error(
                Constante.MENSAJE_ERROR_CONEXION,
                "Error:",
                this.utilitario.setOptionsToat()
              );
            }
          }
        );
    }
  }

  public getDepartamentos(): void {
    this.service
      .postGeneric(
        "parametro/departamentos",
        "msopmcovid",
        this.setRequesUbigeo(null, null)
      )
      .pipe()
      .subscribe((response) => {
        if (response.codigo === Constante.RESPUESTA_OK) {
          this.departamentos = response.data;

          if (this.iniciaSelectDefault) {
            this.filtro.codigoDepartamento = "15";
            this.getProvincias("15");
          } else {
            this.filtro.codigoDepartamento = ""; //
            this.filtro.codigoProvincia = "";
            this.filtro.codigoDistrito = "";
          }
        }
      });
  }

  public getProvincias(codigoDepartamento: string): void {
    this.service
      .postGeneric(
        "parametro/provincias",
        "msopmcovid",
        this.setRequesUbigeo(codigoDepartamento, null)
      )
      .pipe()
      .subscribe((response) => {
        if (response.codigo === Constante.RESPUESTA_OK) {
          this.provincias = response.data;
          this.distritos = [];
          if (this.iniciaSelectDefault) {
            this.filtro.codigoProvincia = "01";
            this.getDistritos();
          } else {
            this.filtro.codigoProvincia = "";
            this.filtro.codigoDistrito = ""; //c
          }
        }
      });
  }

  public getDistritos(): void {
    this.service
      .postGeneric(
        "parametro/distritos",
        "msopmcovid",
        this.setRequesUbigeo(
          this.filtro.codigoProvincia,
          this.filtro.codigoDepartamento
        )
      )
      .pipe()
      .subscribe((response) => {
        if (response.codigo === Constante.RESPUESTA_OK) {
          this.distritos = response.data;
          this.filtro.codigoDistrito = "";
        }
      });
  }

  public setRequesUbigeo(codigo: string, codigoDos: string) {
    return {
      filtro: {
        codigo,
        codigoDos,
      },
    };
  }

  public setFiltro(codigoDepa?: string) {
    return {
      filtro: {
        nombreProducto: this.filtro.nombreProducto,
        pagina: 1,
        tamanio: 10,
        codigo: codigoDepa,
      },
    };
  }

  public setFiltroP(token: string, nombreProducto?: string) {
    return {
      filtro: {
        nombreProducto,
        pagina: 1,
        tamanio: 10,
        tokenGoogle: token,
      },
    };
  }

  public seleccionarProducto(item: any) {
    if (item !=null){
      this.nombreProducto = item.nombreProducto;
      this.filtro.nombreProducto =
        item.nombreProducto +
        " " +
        item.concent +
        " " +
        item.nombreFormaFarmaceutica;

      //this.listaProductosFiltrados = [];
      //this.mostrarProductoFiltrado = false;
      this.filtro.codigoProducto = item.grupo; // se almacena el grupo en codigoProducto
      this.filtro.codGrupoFF = item.codGrupoFF;
      this.filtro.concent = item.concent;
    }
  }

  verDetalle(producto: any, isSectorPublico?: boolean): void {
    let precio1 = producto.precio1;

    if (producto.setcodigo === "Público") {
      precio1 = producto.precio2;
    }
    if (producto.precio1 === null) {
      precio1 = producto.precio2;
      isSectorPublico = true;
    }

    const product = {
      codigoProducto: producto.codProdE,
      codEstablecimiento: producto.codEstab,
      fracciones: producto.fracciones,
      precio1,
      setcodigo: producto.setcodigo,
      isSectorPublico,
      concent: producto.concent,
      nombreFormaFarmaceutica: producto.nombreFormaFarmaceutica,
    };
    const modalRef = this.modalService.open(ModalProductoDetalleComponent, {
      centered: true,
      size: "lg",
    });
    modalRef.componentInstance.producto = product;
  }

  verDetalleV2(producto: any, isSectorPublico?: boolean): void {
    const product = {
      codigoProducto: producto.codProdE,
      codEstablecimiento: producto.codEstab,
      fracciones: producto.fracciones,
      setcodigo: producto.setcodigo, // falta
      isSectorPublico,
      concent: producto.concent, // falta
      nombreFormaFarmaceutica: producto.nombreFormaFarmaceutica, // falta
    };
    const modalRef = this.modalService.open(ModalProductoDetalleComponent, {
      centered: true,
      size: "lg",
    });
    modalRef.componentInstance.producto = product;
  }

  limpiar(): void {
    this.filtro.codigoProducto = null;
    this.filtro.nombreProducto = "";
    this.filtro.codigoDepartamento = "";
    this.filtro.codigoProvincia = "";
    this.filtro.codigoDistrito = "";
    this.filtro.codigoTipoEstablecimiento = "";
    this.filtro.nombreLaboratorio = null;
    this.filtro.nombreEstablecimiento = null;
    this.filtro.catEstablecimiento = "";
    this.existePrecioMinimo = false;
    this.productos = [];
    //this.mostrarProductoFiltrado = false;
    //this.listaProductosFiltrados = [];
    this.collectionSize = 0;
    this.existeData = false;
    this.departamentos = [];
    this.provincias = [];
    this.distritos = [];
    this.filtro.codGrupoFF = "";
    this.filtro.concent = "";
    this.getDepartamentos();
    this.page = 1;
    this.pageSize = 10;
    this.mostrarTableVacia = false;
    this.nombreProducto = "";
    this.prescrito = false;
  }

  exportarExcel(token: string): void {
    if (this.collectionSize > 0) {
      if (this.validarProductoExistenteParaDescargaExcel()) {
        this.showGeneralProgres = true;
        this.service
          .descargarGeneric(
            "preciovista/precioexcelciudadano",
            "msopmcovid",
            this.setRequest(token)
          )
          .pipe(finalize(() => (this.showGeneralProgres = false)))
          .subscribe(
            (response) => {
              var blob = new Blob([response], {type: "application/vnd.ms-excel"});
              saveAs(blob, "preciosProductos" + Constante.EXTENSION_XLSX);
            },
            (error: Error) => {
              console.error(error);
              this.toastr.error(
                Constante.MENSAJE_ERROR_CONEXION,
                "Error:",
                this.utilitario.setOptionsToat()
              );
            }
          );
      }
    } else {
      this.toastr.warning(
        Constante.SIN_DATA_POR_EXPORTAR,
        Constante.ATENTION,
        this.utilitario.setOptionsToat()
      );
    }
  }

  descargarCatalogoProductos(token: string): void {
    this.showGeneralProgres = true;
    this.service
      .descargarGeneric(
        "producto/catalogoproductos",
        "msopmcovid",
        this.setRequestCatalogo(token)
      )
      .pipe(finalize(() => (this.showGeneralProgres = false)))
      .subscribe(
        (response) => {
          var blob = new Blob([response], {type: "application/vnd.ms-excel"});
          saveAs(blob, "catalogoproductos" + Constante.EXTENSION_XLSX);
        },
        (error: Error) => {
          console.error(error);
          this.toastr.error(
            Constante.MENSAJE_ERROR_CONEXION,
            "Error:",
            this.utilitario.setOptionsToat()
          );
        }
      );
  }

  setRequestCatalogo(tokenGoogle: string) {
    return {
      filtro: {
        situacion: "ACT",
        tokenGoogle,
      },
    };
  }

  public openModalInformativo(): void {
    const data = {
      title: "¿Cómo elegir?",
      message: "¿Desea duplicar los precios?",
    };
    const modalRef: NgbModalRef = this.modalService.open(
      ModalInformativoInicioComponent,
      { centered: true, backdrop: "static" }
    );

    modalRef.result.then(
      (closed) => console.log(),
      (dismissed) => {
        if (dismissed) {
        }
      }
    );
  }

  validarProductoExistenteParaDescargaExcel(): boolean {
    let response = true;
    if (
      this.filtro.codigoProducto === null ||
      this.filtro.codigoProducto === undefined ||
      this.filtro.codigoProducto === ""
    ) {
      this.toastr.warning(
        Constante.SIN_PRODUCTO_POR_DESCARGAR,
        "Atención:",
        this.utilitario.setOptionsToat()
      );
      response = false;
    }
    return response;
  }

  id = "precio2";
  reverse = false;
  
  sort(id: string): void {
    //console.log("NORMAL - ordenando por..." + id);
    this.id = id;
    this.reverse = !this.reverse;
  
    if (id === 'fecha') {
      this.productos.sort((a, b) => {
        const dateA = this.parseDate(a.fecha);
        const dateB = this.parseDate(b.fecha);
  
        if (dateA && dateB) {
          if (this.reverse) {
            return dateB.getTime() - dateA.getTime(); // Orden descendente
          } else {
            return dateA.getTime() - dateB.getTime(); // Orden ascendente
          }
        }
        return 0; // Manejar en caso de fechas no válidas
      });
      
    } else if (id === 'precio2') {
      // Ordenar por precio (numérico)
      this.productos.sort((a, b) => {
        const precioA = a.precio2 ? parseFloat(a.precio2) : 0;
        const precioB = b.precio2 ? parseFloat(b.precio2) : 0;
  
        if (this.reverse) {
          return precioB - precioA; // Orden descendente
        } else {
          return precioA - precioB; // Orden ascendente
        }
      });
      
    } else {
      this.productos.sort((a, b) => {
        if (this.reverse) {
          return b[id].localeCompare(a[id]);
        } else {
          return a[id].localeCompare(b[id]);
        }
      });
    }
  }

  parseDate(fechaStr: string): Date | null {
    try {
      // Verificar el formato que estamos recibiendo
      // console.log("Fecha recibida:", fechaStr);
  
      const [fecha, hora, periodo] = fechaStr.split(' ');
      const [dia, mes, anio] = fecha.split('/');
      const [horaStr, minutos, segundos] = hora.split(':');
    
      let horas = parseInt(horaStr, 10);
  
      if (periodo === 'p. m.' && horas < 12) {
        horas += 12;
      } else if (periodo === 'a. m.' && horas === 12) {
        horas = 0;
      }
  
      const parsedDate = new Date(
        parseInt(anio, 10), // Año
        parseInt(mes, 10) - 1, // Mes (0-indexed)
        parseInt(dia, 10), // Día
        horas,
        parseInt(minutos, 10),
        parseInt(segundos, 10)
      );
      
      // Comprobar si la fecha es válida
      // console.log("Fecha parseada:", parsedDate);
      return parsedDate;
  
    } catch (error) {
      // console.error("Error al parsear la fecha:", fechaStr, error);
      return null;
    }
  }

  getCatEstablecimiento(): void {
    this.catEstablecimientos = [
      { codigo: "06", descripcion: "FARES" },
      { codigo: "03", descripcion: "FARMACIAS" },
      { codigo: "04", descripcion: "BOTICAS" },
    ];
  }

  // WITH CAPTCHA
  public getTokenGoogle(service: string): void {
    this.recaptchaV3Service
      .execute("consultaAlCiudadano")
      .subscribe((token) => {
        if (token) {
          switch (service) {
            case "CONSULTA_CIUDADANO": {
              this.listarPrecioProductos(token);
              break;
            }
            case "EXPORTAR_CIUDADANO": {
              this.exportarExcel(token);
              break;
            }
            case "EXPORTAR_CATALOGO": {
              this.descargarCatalogoProductos(token);
              break;
            }
            default: {
              console.error("Error en servicio");
            }
          }
        } else {
          console.error("Sin token de google!");
        }
      });
  }

  cars = [
    {"codigoProducto":1,"nombreProducto":"AMITRIPTILINA CLORHIDRATO","concent":"25mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"TABLETA","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":2,"nombreProducto":"AMLODIPINO (como besilato)","concent":"10 mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"TABLETA","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":3,"nombreProducto":"AMLODIPINO (como besilato)","concent":"5mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"TABLETA","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":4,"nombreProducto":"AMOXICILINA ","concent":"250mg/5mL","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"SUSPENSION","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":5,"nombreProducto":"AMOXICILINA ","concent":"500 mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"TABLETA","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":6,"nombreProducto":"Amoxicilina + ácido clavulánico (como sal potásica)","concent":"500 mg + 125 mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"TABLETA","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":7,"nombreProducto":"ATORVASTATINA (como sal cálcia)","concent":"20 mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"TABLETA","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":8,"nombreProducto":"AZITROMICINA ","concent":"500mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"TABLETA","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":9,"nombreProducto":"AZITROMICINA","concent":"200 mg/5 mL","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"SUSPENSION","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":10,"nombreProducto":"BECLOMETASONA DIPROPIONATO","concent":"250 mcg/dosis","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"AEROSOL","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":11,"nombreProducto":"CAPTOPRIL ","concent":"25 mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"TABLETA","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":12,"nombreProducto":"CARBAMAZEPINA ","concent":"200 mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"TABLETA","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":13,"nombreProducto":"CEFALEXINA ","concent":"250mg/5mL","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"SUSPENSION","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":14,"nombreProducto":"CEFALEXINA ","concent":"500 mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"TABLETA","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":15,"nombreProducto":"CLINDAMICINA (Como clorhidrato)","concent":"300mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"TABLETA","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":16,"nombreProducto":"CLONAZEPAM ","concent":"2mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"TABLETA","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":17,"nombreProducto":"CLONAZEPAM","concent":"0,5mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"TABLETA","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":18,"nombreProducto":"CLORFENAMINA MALEATO","concent":"2 mg/5 mL","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"SOLUCION","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":19,"nombreProducto":"CLORFENAMINA MALEATO","concent":"4mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"TABLETA","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":20,"nombreProducto":"CLOTRIMAZOL","concent":"500mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"OVULTO / TABLETA Vag","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":21,"nombreProducto":"ENALAPRIL MALEATO","concent":"10mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"TABLETA","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":22,"nombreProducto":"ENALAPRIL MALEATO","concent":"20mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"TABLETA","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":23,"nombreProducto":"FENITOINA","concent":"100 mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"TABLETA","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":24,"nombreProducto":"FLUCONAZOL ","concent":"150 mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"TABLETA","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":25,"nombreProducto":"FLUOXETINA (como clorhidrato)","concent":"20mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"TABLETA","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":26,"nombreProducto":"GLIBENCLAMIDA ","concent":"5mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"TABLETA","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":27,"nombreProducto":"IBUPROFENO ","concent":"400mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"TABLETA","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":28,"nombreProducto":"LORATADINA ","concent":"10mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"TABLETA","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":29,"nombreProducto":"LORATADINA ","concent":"5mg/5mL","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"SOLUCION","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":30,"nombreProducto":"LOSARTAN POTASICO","concent":"50 mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"TABLETA","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":31,"nombreProducto":"METFORMINA CLORHIDRATO","concent":"850mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"TABLETA","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":32,"nombreProducto":"NAPROXENO (COMO BASE O SAL SODICA)","concent":"500 mg /550mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"TABLETA","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":33,"nombreProducto":"OMEPRAZOL ","concent":"20 mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"TABLETA","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":34,"nombreProducto":"PARACETAMOL ","concent":"100 mg/mL","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"SOLUCION","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":35,"nombreProducto":"PARACETAMOL ","concent":"120mg/5mL","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"SOLUCION","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":36,"nombreProducto":"PARACETAMOL ","concent":"500 mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"TABLETA","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":37,"nombreProducto":"PREDNISONA ","concent":"20mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"TABLETA","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":38,"nombreProducto":"PREDNISONA ","concent":"50mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"TABLETA","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":39,"nombreProducto":"PREDNISONA ","concent":"5mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"TABLETA","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":40,"nombreProducto":"PREDNISONA ","concent":"5mg/5mL","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"SOLUCION","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":41,"nombreProducto":"Ranitidina (Como Clorhidrato)","concent":"300mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"TABLETA","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":42,"nombreProducto":"SALBUTAMOL (como sulfato)","concent":"100 mcg/dosis","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"AEROSOL","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":43,"nombreProducto":"SERTRALINA (Como clorhidrato)","concent":"50mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"TABLETA","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":44,"nombreProducto":"TAMSULOSINA CLORHIDRATO","concent":"400mcg (0,4mg)","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"TABLETA DE liberación modificada","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":45,"nombreProducto":"Dexametasona fosfato(como sal sódica)","concent":"4mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"Inyectable","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":46,"nombreProducto":"Epinefrina (como clorhidrato o tartrato)","concent":"1mg/ml","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"Inyectable","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"},
    {"codigoProducto":47,"nombreProducto":"Metilprednisolona (como succinato sódico)","concent":"500mg","presentacion":null,"fracciones":null,"nombreFormaFarmaceutica":"Inyectable","nroRegistroSanitario":null,"titular":null,"grupo":574,"codGrupoFF":"24"}
];
}
