import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";
import { Constante } from "../nucleo/constante/Constante";
import { Observable, Observer } from "rxjs";
import { EnvironmentService } from "../nucleo/configuracion/environment.service";

@Injectable()
export class AuthApiService {
  usuarios: any[] = [
    {
      id: 1,
      usuario: "admin",
      password: "123456",
      createdAt: "2017-11-04",
      updatedAt: "2017-11-04",
      token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9",
      codigo: "00",
    },
    {
      id: 2,
      usuario: "user2@correo.com",
      password: "123456",
      createdAt: "2017-11-04",
      updatedAt: "2017-11-04",
      token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9",
      codigo: "00",
    },
    {
      id: 3,
      usuario: "user3s@correo.com",
      password: "123456",
      createdAt: "2017-11-04",
      updatedAt: "2017-11-04",
      token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9",
      codigo: "00",
    },
    {
      id: 4,
      usuario: "admin@correo.com",
      password: "123456",
      createdAt: "2017-11-04",
      updatedAt: "2017-11-04",
      token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9",
      codigo: "00",
    },
  ];

  userSinToken: any = {
    id: 1,
    usuario: "",
    password: "",
    createdAt: "",
    updatedAt: "",
    token: "",
    codigo: "00",
  };

  constructor(
    private http: HttpClient,
    private configService: EnvironmentService
  ) {}

  registro(path: string, usuario: any): Observable<any> {
    return this.http.post<any>(
      `${this.configService.servicio}:${this.configService.puerto}/${path}/registrar`,
      usuario,
      { observe: "body", responseType: "json" }
    );
  }

  login(usuario: any, mservice: string): Observable<any> {
    return this.http.post<any>(
      `${this.configService.servicio}:${this.configService.puerto}/${mservice}/usuario/login`,
      usuario,
      { observe: "response" as "body" }
    );
  }

  permisosPorModulo(usuario: any): Observable<any> {
    return this.http.post<any>(
      `${this.configService.servicio}:${this.configService.puerto}/seguridad/modular`,
      usuario,
      { observe: "body", responseType: "json" }
    );
  }

  /*   getConfiguracion(codigoEmpresa: string): Observable<any> {
    return this.http.post<any>(`http://${SERVICIO}:${PUERTO}/seguridad/modular`, usuario, { observe: 'body', responseType: 'json' });
  } */

  fakeLogin(usuario: any): Observable<any> {
    const observador: Observable<any> = Observable.create(
      (observer: Observer<any>) => {
        setTimeout(() => {
          const registro: any = this.usuarios.find(
            (user) =>
              user.usuario.toUpperCase() === usuario.usuario.toUpperCase() &&
              user.password === usuario.password
          );
          registro === undefined
            ? observer.next(this.userSinToken)
            : observer.next(registro);
        }, 1500);
      }
    );
    return observador;
  }
}
