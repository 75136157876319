import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { IUsuario } from "../nucleo/interface/IUsuario";
import { Observable, Subject } from "rxjs";
import { Router } from "@angular/router";
import { AuthApiService } from "./auth-api.service";
import { map } from "rxjs/operators";

@Injectable()
export class AutenticacionService {
  rutaApi: string = "http://localhost:4000";
  autenticado: boolean = false;
  private usuario: IUsuario;

  cambioEstado = new Subject();

  constructor(
    private http: HttpClient,
    private router: Router,
    private authApiService: AuthApiService
  ) {}

  /*   loginCore(usuario: IUsuario): void {
    this.authApiService.login(usuario).subscribe(response => {
      console.log(response);
    })
    this.http.post<IUsuario>(`${this.rutaApi}/auth/login`, usuario, {
      observe: "body",
      responseType: "json"
    }).subscribe(
      (usuario: IUsuario) => {
        localStorage.setItem("accessToken", usuario.accessToken)
        localStorage.setItem("refreshToken", usuario.refreshToken)
        this.autenticado = true
        this.cambioEstado.next(true)
        //this.router.navigate(["almacen"])
      },
      error => {
        console.log(error)
      }
      )
  }  */

  loginCore(usuario: IUsuario) {
    return this.authApiService.login(usuario, "msopmcovid").pipe(
      map((response: HttpResponse<any>) => {
        if (response) {
          if (response.body.codigo === "00") {
            this.cargarSessionStorage(response);
            return response.body;
          } else {
            // this.borrarSessionStorage();
            return response.body;
          }
        } else {
          // this.borrarSessionStorage();
          return response.body;
        }
      })
    );
  }

  login(usuario: IUsuario) {
    return this.authApiService.login(usuario, "msopmcovid").pipe(
      map((response: any) => {
       
        if (response) {
          if (response.body.codigo === "00") {
            this.cargarSessionStorage(response.body);
            this.setLocalStorage(response.body);
            return response.body;
          } else {
            this.borrarSessionStorage();
            return response.body;
          }
        } else {
          this.borrarSessionStorage();
          return response.body;
        }
      })
    );
  }

  obtenerNuevoToken(refreshToken: string): Observable<string> {
    return this.http.post<string>(
      `${this.rutaApi}/auth/nuevo-token`,
      { refreshToken },
      {
        observe: "body",
        responseType: "json",
      }
    );
  }

  registrarUsuario(usuario: IUsuario): Observable<IUsuario> {
    return this.http.post<IUsuario>(`${this.rutaApi}/auth/registro`, usuario, {
      observe: "body",
      responseType: "json",
    });
  }

  estaAutenticado(): boolean {
    return this.autenticado;
  }

  setLocalStorage(response: any): void {
    localStorage.setItem("accessToken", response.entidad.rolToken);
    //localStorage.setItem("refreshToken", usuario.refreshToken)
  }
  logout(): void {
    this.autenticado = false;
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    sessionStorage.clear();
    this.usuario = undefined;

    this.router.navigate(["/login"]);

    this.cambioEstado.next(false);
  }

  obtenerUsuarios(): Observable<IUsuario[]> {
    return this.http.get<IUsuario[]>(`${this.rutaApi}/auth/listado`, {
      observe: "body",
      responseType: "json",
    });
  }

  private cargarSessionStorage(response: any) {
    this.autenticado = true;
    sessionStorage.setItem("token", response.entidad.rolToken);
    sessionStorage.setItem("usuario", this.generarJsonUsuario(response));
    //sessionStorage.setItem('token', response.headers.get('authorization'));
    //sessionStorage.setItem('usuario', this.generarJsonUsuario(response));
    // sessionStorage.setItem('empresa', this.generarJsonEmpresa(response));
    // sessionStorage.setItem('modulos', JSON.stringify(response.body.entidad.rol.permisos));
    this.cambioEstado.next(true);
  }

  private borrarSessionStorage() {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("usuario");
    sessionStorage.removeItem("empresa");
    sessionStorage.removeItem("modulos");
    sessionStorage.removeItem("permisos");
    this.autenticado = false;
    this.cambioEstado.next(false);
  }

  obtenerDatosUsuario(): any {
    let usuario: any;
    usuario = JSON.parse(sessionStorage.getItem("usuario"));
    usuario.usuario = this.decryptDato(usuario.usuario);
    usuario.razonComercial = usuario.razonComercial;
    usuario.emailAdmin = this.decryptDato(usuario.emailAdmin);
    //return (usuario = JSON.parse(sessionStorage.getItem("usuario")));
    return usuario;
  }

  private generarJsonUsuario(response: any) {
    return JSON.stringify({
      usuario: this.encryptDato(response.entidad.usuario),
      razonComercial: response.entidad.nombreComercial,
      tipoEstablecimiento: this.eliminarDuplicados(
        response.entidad.tipoEstablecimientoList
      ),
      emailAdmin: this.encryptDato(response.entidad.emailAdmin),
    });
  }

  private encryptDato(value: string): string {
    const key = "6LdJRrUZAA-AAAFJy4jVu3WO-8CIi4wsFeQ-sX9sdyD";
    const valor1 = key.split("-")[0];
    const valor2 = key.split("-")[1];
    const valor3 = key;
    const valor4 = key.split("-")[3];

    const sinEncriptar =
      valor3 + key + valor1 + "@@@" + value + "@@@" + valor3 + valor4;
    const encriptado = btoa(sinEncriptar);

    return encriptado;
  }

  private decryptDato(encriptado: string): string {
    const sinEncriptar = atob(encriptado);
    return sinEncriptar.split("@@@")[1];
  }

  private eliminarDuplicados(lista: Array<any>): any {
    var hash = {};
    lista = lista.filter(function (current) {
      var exists = !hash[current.codigo];
      hash[current.codigo] = true;
      return exists;
    });
    return lista;
  }
}
