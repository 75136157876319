import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import {
  LocationStrategy,
  PathLocationStrategy,
  HashLocationStrategy,
  DatePipe
} from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/layout.component';
import { SharedModule } from './shared/shared.module';
import { BreadcrumbsComponent } from './layouts/breadcrumbs/breadcrumbs.component';
import { TitleComponent } from './layouts/title/title.component';
import { LoginComponent } from './authentication/login/login.component';
//import { ChangePasswordComponent } from './authentication/change-password/change-password.component';
import { SettingService } from './nucleo/configuracion/setting.service';
import { FooterComponent } from './layouts/footer/footer.component';
import { EnvServiceProvider } from './nucleo/configuracion/environment.service.provider';
import { routing } from './routing/app.routing';
import { AuthorizationGuard } from './nucleo/guards/auth-guards';
import { AuthApiService } from './seguridad/auth-api.service';
import { AutenticacionService } from './seguridad/autenticacion.service';
// tslint:disable-next-line: import-spacing
import { ConsultaProductosListarComponent } from
'./publico/consulta-productos/consulta-productos-listar/consulta-productos-listar.component';
import { ConsultaProductosGenericosComponent } from
'./publico/consulta-productos/consulta-productos-genericos/consulta-productos-genericos.component';
import { ModalProductoDetalleComponent } from './components/modal-producto-detalle/modal-producto-detalle.component';
import { ModalEnviarCorreoComponent } from './components/modal-enviar-correo/modal-enviar-correo.component';
import { ModalPrecioProductoComponent } from './components/modal-precio-producto/modal-precio-producto.component';
import { ModalPrecioNuevoComponent } from './components/modal-precio-nuevo/modal-precio-nuevo.component';
import { ModalConfirmarComponent } from './components/modal-confirmar/modal-confirmar.component';
import { ModalComoElegirComponent } from './components/modal-como-elegir/modal-como-elegir.component';
import { ModalEditarObservadoComponent } from './components/modal-editar-observado/modal-editar-observado.component';
import {
  RecaptchaModule,
  RecaptchaFormsModule,
  RECAPTCHA_V3_SITE_KEY,
  ReCaptchaV3Service,
} from 'ng-recaptcha';
import { ModalConfirmarSiNoComponent } from './components/modal-confirmar-si-no/modal-confirmar-si-no.component';
import { ModalInformativoInicioComponent } from './components/modal-informativo-inicio/modal-informativo-inicio.component';
import { ModalReprocesarComponent } from './components/modal-reprocesar/modal-reprocesar.component';
import { ModalSuspensionComponent } from './components/modal-suspension/modal-suspension.component';
import { ModalComunicacionComponent } from './components/modal-comunicacion/modal-comunicacion.component';

import {
  AppInterceptor,
  interceptorProvider,
} from './interceptor/app.interceptor';
import { DatosDirisService } from './servicio/datosDiris.service';
import { ModalContactoComponent } from './components/modal-contacto/modal-contacto.component';
import { EnvironmentService } from './nucleo/configuracion/environment.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { RecuperaPassComponent } from './authentication/recupera-pass/recupera-pass.component';
import { ModalRecomendacionesComponent } from './components/modal-recomendaciones/modal-recomendaciones.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';


@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    BreadcrumbsComponent,
    TitleComponent,
    LoginComponent,
    //ChangePasswordComponent,
    FooterComponent,
    ConsultaProductosListarComponent,
    ConsultaProductosGenericosComponent,
    RecuperaPassComponent
  ],
  imports: [
    BrowserAnimationsModule,
    SharedModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    routing,
    RecaptchaModule,
    RecaptchaFormsModule,
    BrowserModule,
    NgSelectModule,
    PdfViewerModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: '6LfTRbUZAAAAAC4PZ6QF3Oz_IElVRZpnW9zzA7JD',
    },
    // { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    SettingService,
    EnvServiceProvider,
    AuthorizationGuard,
    AutenticacionService,
    AuthApiService,
    ReCaptchaV3Service,
    DatosDirisService,
    DatePipe
  ],
  entryComponents: [
    ModalProductoDetalleComponent,
    ModalEnviarCorreoComponent,
    ModalPrecioProductoComponent,
    ModalPrecioNuevoComponent,
    ModalConfirmarComponent,
    ModalComoElegirComponent,
    ModalEditarObservadoComponent,
    ModalConfirmarSiNoComponent,
    ModalInformativoInicioComponent,
    ModalReprocesarComponent,
    ModalContactoComponent,
    ModalSuspensionComponent,
    ModalComunicacionComponent,
	  ModalRecomendacionesComponent
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
