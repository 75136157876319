import { Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
  selector: "[numeric]",
})
export class NumericDirective {
  @Input() numericType: string; // number | decimal

  private regex = {
    number: new RegExp(/^\d+$/),
    decimal: new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g),
    //decimal: new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g)
    //decimal: new RegExp(/^\-?[0-9]+(?:\.[0-9]{1,2})?$/),
    //decimal: new RegExp(/^[0-9]+(\\.)+([0-9]{1,2})?$/),
    //decimal: new RegExp(/^[0-9]*$|^[0-9]{1,3}\.[0-9]{1,3}$/),
    //decimal: new RegExp(/^(\d{1}\.)?(\d+\.?)+(\d{2})?$/g),
  };

  private specialKeys = {
    number: ["Backspace", "Tab", "End", "Home", "ArrowLeft", "ArrowRight"],
    decimal: ["Backspace", "Tab", "End", "Home", "ArrowLeft", "ArrowRight"],
  };

  constructor(private el: ElementRef) {}

  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys[this.numericType].indexOf(event.key) !== -1) {
      return;
    }

    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);

    if (next && !String(next).match(this.regex[this.numericType])) {
      event.preventDefault();
    }
    /* if (!this.validateDosDecimales(next)) {
      event.preventDefault();
    } */
  }

  @HostListener("paste", ["$event"])
  blockPaste(e: KeyboardEvent) {
    if (this.specialKeys[this.numericType].indexOf(e.key) !== -1) {
      return;
    }

    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(e.key);
    if (next && !String(next).match(this.regex[this.numericType])) {
      e.preventDefault();
    }
  }

  validateDosDecimales(valor: string): boolean {
    const valorArr = valor.split(".");
    const valorDecimal = valorArr[1];
    if (valorDecimal) {
      if (valorDecimal.length > 2) {
        return false;
      }
    }

    return true;
  }
}
