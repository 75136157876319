import { Component, OnInit } from "@angular/core";
import { AutenticacionService } from "../../seguridad/autenticacion.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Constante } from "../../nucleo/constante/Constante";
import { finalize } from "rxjs/operators";
import { Subscription } from "rxjs";
import { OnExecuteData, ReCaptchaV3Service } from "ng-recaptcha";
import { TranslateService } from "@ngx-translate/core";
import { MD5 } from "crypto-js";
import { DatePipe } from '@angular/common';
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ModalSuspensionComponent } from "../../components/modal-suspension/modal-suspension.component";
import { ModalRecomendacionesComponent } from "../../components/modal-recomendaciones/modal-recomendaciones.component";
import { EnvironmentService } from '../../nucleo/configuracion/environment.service';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  entidad: any = {};
  mensaje: string;
  codigoLogin: string;
  flag = false;
  showGeneralProgres = false;
  captchaCode: string = null;
  verPass = false;
  clasePass = "password";
  systemTitle = Constante.SYSTEM_TITLE;
  logoMinsa = Constante.LOGO_MINSA;
  tokenGoogle: string = "";
  private subscription: Subscription;

  fondoOPM = Constante.FONDO_OPM;
  tituloOPM = Constante.TITULO_OPM;
  fondoOPMMovil = Constante.FONDO_OPM_MOVIL;
  tituloOPMMovil = Constante.TITULO_OPM_MOVIL;

  idiomas = [
    { value: "es", description: "Español" },
    { value: "en", description: "Inglés" },
    // { value: "qu", description: "Quechua" }, 
    /*    { value: "fr", description: "Francés" }, */
  ];

  constructor(
    private autenticacionService: AutenticacionService,
    public router: Router,
    private rutaActual: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service,
    public translate: TranslateService,
    public datepipe: DatePipe,
    private modalService: NgbModal,
    private configService: EnvironmentService
  ) {
    this.setConfigLanguaje();

    var msjSuspension = false; //true: msj suspension, false: msj recomendaciones

    if (msjSuspension){
      this.openModalSuspension();
      //this.openModalMultipleImg();
    } else if (this.validateRecomendaciones()){
      this.openModalRecomendaciones();
    }
  }

  public setConfigLanguaje() {
    let browserLang = localStorage.getItem("languaje");
    if (browserLang === null) {
      browserLang = "es";
    }
    this.translate.use(browserLang.match(/en|fr|qu|es/) ? browserLang : "es");
  }


  // setConfigLanguaje() {
  //   this.translate.addLangs(['en', 'fr', 'es', 'qu']);
  //   this.translate.setDefaultLang('es');
  //   const browserLang = this.translate.getBrowserLang();
  //   localStorage.setItem('languaje', browserLang);
  //   this.translate.use(browserLang.match(/en|fr|qu|es/) ? browserLang : "es");
  // }

  public ngOnInit() {
    // LOGIN WITH CAPTCHA
    /*     this.subscription = this.recaptchaV3Service.onExecute.subscribe(
      (data: OnExecuteData) => {
        console.log("DATA: ", data);
      }
    ); */
  }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  //LOGIN WITH CAPTCHA
  /*  public getTokenGoogle(): void {
    this.recaptchaV3Service.execute("importantAction").subscribe((token) => {
      if (token) {
        this.login(token);
      }
    });
  } */

  resolved(captchaResponse: string) {
    this.captchaCode = captchaResponse;
  }

  //LOGIN WITH CAPTCHA
  /*   login(tokenGoogle: string): void {
    this.showGeneralProgres = true;
    this.flag = false;
    this.mensaje = null;
    this.entidad.tokenGoogle = tokenGoogle;
    this.autenticacionService
      .login(this.entidad)
      .pipe(finalize(() => (this.showGeneralProgres = false)))
      .subscribe(
        (response) => {
          if (response.codigo === "00") {
            this.router.navigate([""]);
          } else {
            this.flag = true;
            this.mensaje = response.mensaje;
          }
        },
        (error: Error) => {
          this.flag = true;
          this.mensaje = Constante.MENSAJE_ERROR_CONEXION;
        }
      );
  } */

  login(): void {
    this.showGeneralProgres = true;
    this.flag = false;
    this.mensaje = null;
    this.codigoLogin = null;
    this.recaptchaV3Service.execute("loginAction").subscribe((token) => {
      if (token) {
        this.entidad.tokenGoogle = token;
        this.autenticacionService
          .login(this.entidad)
          .pipe(finalize(() => (this.showGeneralProgres = false)))
          .subscribe(
            (response) => {
              if (response.codigo === "00") {
                this.router.navigate([""]);
              } else {
                this.flag = true;
                this.mensaje = response.mensaje;
                this.codigoLogin = MD5(this.entidad.usuario + " " + this.datepipe.transform(new Date(), 'ddMMyyyyHHmm')).toString() + "\n" 
              + this.datepipe.transform(new Date(), 'dd/MM/yyyy HH:mm');
              }
            },
            (error: Error) => {
              console.error('error', error);
              this.flag = true;
              this.mensaje = Constante.MENSAJE_ERROR_CONEXION;
              this.codigoLogin = MD5(this.entidad.usuario + " " + this.datepipe.transform(new Date(), 'ddMMyyyyHHmm')).toString() + "\n" 
              + this.datepipe.transform(new Date(), 'dd/MM/yyyy HH:mm');
            }
          );
      }
    });
  }

  validateCaptcha(): boolean {
    let response = true;
    if (this.captchaCode === null) {
      this.flag = true;
      this.mensaje = "Demuestra que no eres un robot";
      response = false;
    }
    return response;
  }

  regresar(): void {
    this.router.navigate(["../consulta-producto"], {
      relativeTo: this.rutaActual,
    });
  }

  verPassword(): void {
    if (this.verPass === false) {
      this.verPass = true;
      this.clasePass = "text";
    } else {
      this.verPass = false;
      this.clasePass = "password";
    }
  }

  public openModalSuspension(): void {
    
    //MENSAJE DE SUSPENSION
    const modalSus: NgbModalRef = this.modalService.open(
      ModalSuspensionComponent,
      { centered: true, backdrop: "static", size: 'lg' }
    );

    modalSus.result.then(
      (closed) => console.log(),
      (dismissed) => {
        if (dismissed) {
        }
      } 
    );
  }

  public openModalRecomendaciones(): void {
    
    //MENSAJE DE RECOMENDACIONES
    const modalRec: NgbModalRef = this.modalService.open(
      ModalRecomendacionesComponent,
      { centered: true, backdrop: "static", size: 'lg' }
    );

    modalRec.result.then(
      (closed) => console.log(),
      (dismissed) => {
        if (dismissed) {
        }
      } 
    );
  }

  validateRecomendaciones(): boolean {
    /*
    if (!this.configService.recomendaciones){
      return false;
    }
    */
    let response = false;
    var fechaActual = new Date();
    //calcular mitad de mes
    var mitadMes = new Date(fechaActual.getFullYear(), fechaActual.getMonth(), 14);
    if (fechaActual > mitadMes) {
      response = true;
    }
    return response;
  }

  public openModalMultipleImg(): void {
    
    //MENSAJE DE SUSPENSION
    const modalSus: NgbModalRef = this.modalService.open(
      ModalSuspensionComponent,
      { centered: true, backdrop: "static", size: 'lg' }
    );
    
    modalSus.componentInstance.img_src = "assets/images/flyer_1.png";
    modalSus.componentInstance.modal_class = "";

    modalSus.result.then(
      (closed) => console.log(),
      (dismissed) => {
        if (dismissed) {
        }

        //MENSAJE DE SUSPENSION
        const modalSus: NgbModalRef = this.modalService.open(
          ModalSuspensionComponent,
          { centered: true, backdrop: "static", size: 'lg' }
        );

        modalSus.componentInstance.img_src = "assets/images/flyer_2.png";
        modalSus.componentInstance.modal_class = "modal-sus";

        modalSus.result.then(
          (closed) => console.log(),
          (dismissed) => {
            if (dismissed) {
            }
            if (this.validateRecomendaciones()){
              this.openModalRecomendaciones();
            }
          } 
        );
      } 
    );

  }
}
