import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENUITEMS = [
  {
    label: '',
    main: [
      {
        state: 'precio-productos',
        name: 'Envío de Precios',
        type: 'sub',
        icon: 'ti-layout-grid2-alt',
        rol: 'ADMINISTRADO',
        children: [
          {
            state: 'precio-producto',
            name: 'Mediante llenado de formulario',
            type: 'link',
            icon: 'ti-layout-sidebar-left',
          },
          {
            state: 'listar-carga-masiva',
            name: 'Envío de información mediante archivo excel',
            type: 'link',
            icon: 'ti-layout-sidebar-left',
          },
          {
            state: 'web-service',
            name: 'Mediante web service',
            type: 'link',
            icon: 'ti-layout-sidebar-left',
          },
          /*  {
          state: 'consultar-afiliacion',
          name: 'Consultar Afiliación',
          type: 'link',
          icon: 'ti-layout-sidebar-left'
        },
        {
          state: 'administrar-afiliacion',
          name: 'Administrar Afiliación',
          type: 'link',
          icon: 'ti-layout-sidebar-left'
        } */
        ],
      },
      /*
      {
        state: 'precio-covid',
        name: 'Envío de Precios COVID-19 (DU 059-2020)',
        type: 'sub',
        icon: 'ti-support',
        rol: 'ADMINISTRADO',
        children: [
          {
            state: 'precio-producto',
            name: 'Mediante llenado de formulario',
            type: 'link',
            icon: 'ti-layout-sidebar-left',
          },
          {
            state: 'listar-carga-masiva',
            name: 'Envío de información mediante archivo excel',
            type: 'link',
            icon: 'ti-layout-sidebar-left',
          }
        ],
      },*/
      {
        state: 'solicitud',
        name: 'Solicitudes',
        type: 'sub',
        icon: 'ti-file',
        rol: 'ADMINISTRADO',
        children: [
          {
            state: 'solicitud-producto',
            name: 'Solicitud de nuevo código del OPPF',
            type: 'link',
            icon: 'ti-layout-sidebar-left',
          }
        ],
      },
      {
        state: 'reportes',
        name: 'Reportes',
        type: 'sub',
        icon: 'ti-bar-chart',
        rol: 'ADMINISTRADO',
        children: [
          {
            state: 'reporte-precios',
            name: 'Reporte de precios',
            type: 'link',
            icon: 'ti-layout-sidebar-left',
          },
          {
            state: 'reporte-inscripcion',
            name: 'Constancia de inscripción',
            type: 'link',
            icon: 'ti-layout-sidebar-left',
          },
        ],
      },
      {
        state: 'atencion-consultas',
        name: 'Atención a consultas',
        type: 'sub',
        icon: 'ti-headphone-alt',
        rol: 'ADMINISTRADO',
        children: [
          {
            state: 'establecimientos',
            name: 'Consultas',
            type: 'link',
            icon: 'ti-layout-sidebar-left',
          },
          {
            state: 'change-password',
            name: 'Cambiar Contraseña',
            type: 'link',
            icon: 'ti-layout-sidebar-left',
          },
        ],
      },
      {
        state: 'configuracion',
        name: 'Configuración',
        type: 'sub',
        icon: 'ti-settings',
        rol: 'GESTOR',
        children: [
          {
            state: 'diris',
            name: 'Contactos',
            type: 'link',
            icon: 'ti-layout-sidebar-left',
          },
        ],
      },
      {
        state: 'cerrar-sesion',
        name: 'Cerrar sesión',
        type: 'sub',
        icon: 'ti-power-off',
        rol: 'ADMINISTRADO',
      },
      /* {
      state: 'afiliacion',
        name: 'Afiliacion',
        type: 'sub',
        icon: 'ti-layout-grid2-alt',
      children: [
        {
          state: 'crear-afiliacion',
          name: 'Crear Afiliación',
          type: 'link',
          icon: 'ti-layout-sidebar-left'
        },
        {
          state: 'consultar-afiliacion',
          name: 'Consultar Afiliación',
          type: 'link',
          icon: 'ti-layout-sidebar-left'
        },
        {
          state: 'administrar-afiliacion',
          name: 'Administrar Afiliación',
          type: 'link',
          icon: 'ti-layout-sidebar-left'
        }
      ]
    } */
    ],
  },
];

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }
}
