import { Injectable } from "@angular/core";
import { DatosDiris } from "./interface/DatosDiris";

@Injectable()
export class DatosDirisService {
  constructor() {}
  private datosDiris: DatosDiris;

  public setDatosDiris(datosDiris: DatosDiris): void {
    this.datosDiris = datosDiris;
  }

  public getDatosDiris(): DatosDiris {
    return this.datosDiris;
  }
}
