import {Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter} from '@angular/core';
import {cardToggle, cardClose} from './card-animation';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css'],
  animations: [cardToggle, cardClose],
  encapsulation: ViewEncapsulation.None
})
export class CardComponent implements OnInit {
  @Input() headerContent: string;
  @Input() title: string;
  @Input() blockClass: string;
  @Input() cardClass: string;
  @Input() classHeader = false;
  @Input() btnCerrar : boolean;
  @Input() btnActualizar: boolean;
  @Input() totalData: string;
  @Input() labelTotal: string;
  @Output() actualizarEvent = new EventEmitter<boolean>();
  cardToggle = 'expanded';
  cardClose = 'open';

  constructor() { }

  ngOnInit() {
    this.btnCerrar = this.btnCerrar === undefined ? true : false;
    this.btnActualizar = this.btnActualizar === undefined ? true : false; 
    this.totalData = this.totalData === null ? '0' : this.totalData; 

  }

  toggleCard() {
    this.cardToggle = this.cardToggle === 'collapsed' ? 'expanded' : 'collapsed';
  }

  closeCard() {
    this.cardClose = this.cardClose === 'closed' ? 'open' : 'closed';
  }

  actualizar(){
    this.actualizarEvent.emit(true);
  }

}
