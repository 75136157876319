import { Component, OnInit, Input } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { finalize } from "rxjs/operators";
import { Constante } from "../../nucleo/constante/Constante";
import { ToastrService } from "ngx-toastr";
import { Utilitario } from "../../nucleo/util/utilitario";
import { ServicioService } from "../../servicio/servicio.service";
import { AutenticacionService } from "../../seguridad/autenticacion.service";

@Component({
  selector: "app-modal-precio-producto",
  templateUrl: "./modal-precio-producto.component.html",
  styleUrls: ["./modal-precio-producto.component.css"],
})
export class ModalPrecioProductoComponent implements OnInit {
  @Input() public producto: any;
  entidad: any = {};
  showGeneralProgres = false;
  utilitario = new Utilitario();

  constructor(
    private modalService: NgbModal,
    private toastr: ToastrService,
    private service: ServicioService,
    private autenticacionService: AutenticacionService
  ) {}

  ngOnInit() {
    this.cargarEntidad();
  }

  cargarEntidad(): void {
    this.entidad = this.producto;
  }

  close(response?: any): void {
    this.modalService.dismissAll(response);
  }

  public guardarPrecio(): void {
    this.showGeneralProgres = true;
    this.service
      .guardar("preciovista/guardar", "msopmcovid", this.setRequest())
      .pipe(finalize(() => (this.showGeneralProgres = false)))
      .subscribe(
        (response) => {
          if (response.codigo === Constante.RESPUESTA_OK) {
            this.toastr.success(
              response.mensaje,
              "info ",
              this.utilitario.setOptionsToat()
            );
            this.close(true);
          } else {
            this.toastr.error(
              response.mensaje,
              "Error",
              this.utilitario.setOptionsToat()
            );
          }
        },
        (error: Error) => {
          console.log(error.message);
          this.toastr.error(
            Constante.MENSAJE_ERROR_CONEXION,
            "Error:",
            this.utilitario.setOptionsToat()
          );
        }
      );
  }

  public setRequest() {
    return {
      entidad: {
        codEstab: this.entidad.codEstablecimiento,
        codProdE: this.entidad.codigoProducto,
        precio1: this.entidad.precio1,
        precio2: this.entidad.precio2,
        precio3: this.entidad.precio3,
      },
      usuario: this.autenticacionService.obtenerDatosUsuario().usuario,
    };
  }
}
