import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { IResponseData } from './interface/IResponseData';
import { IRequest } from '../nucleo/interface/IRequest';
import { IModel } from '../nucleo/interface/IModel';
import { IAfiliacion } from '../nucleo/interface/IAfiliacion';
import { EnvironmentService } from '../nucleo/configuracion/environment.service';

@Injectable()
export class ServicioApiService {

  constructor(private http: HttpClient, private configService: EnvironmentService) {

  }

  // carga
  guardar(path: string, mservice: string, object: any): Observable<any> {
    return this.http.post<IResponseData>(`${this.configService.servicio}:${this.configService.puerto}/${mservice}/${path}`,
    object, { observe: 'body', responseType: 'json' });
  }

  listar(path: string, mservice: string, object: any): Observable<any> {
    return this.http.post<IResponseData>(`${this.configService.servicio}:${this.configService.puerto}/${mservice}/${path}`,
    object, { observe: 'body', responseType: 'json' });
  }

  postGeneric(path: string, mservice: string, object: any): Observable<any> {
    return this.http.post<IResponseData>(`${this.configService.servicio}:${this.configService.puerto}/${mservice}/${path}`,
    object, { observe: 'body', responseType: 'json' });
  }

  
  descargarGeneric(path: string, mservice: string, object: object): Observable<Blob> {
    return this.http.post<Blob>(`${this.configService.servicio}:${this.configService.puerto}/${mservice}/${path}`, object,
    { observe: 'body', responseType: 'blob' as 'json' });
  }

  descargarConNombre(path: string, mservice: string, object: object): Observable<HttpResponse<Blob>> {
    console.log("Request enviado:", object); // 🔍 Verifica que el request tenga datos correctos
    return this.http.post<Blob>(
        `${this.configService.servicio}:${this.configService.puerto}/${mservice}/${path}`,
        object,
        { 
          observe: 'response', 
          responseType: 'blob' as 'json',
          withCredentials: true
        }
    );
}

descargarConNombreCSV(path: string, mservice: string, object: object): Observable<HttpResponse<Blob>> {
  console.log("Request enviado:", object); // 🔍 Verifica que el request tenga datos correctos
  return this.http.post<Blob>(
      `${this.configService.servicio}:${this.configService.puerto}/${mservice}/${path}`,
      object,
      { 
        observe: 'response',  // 📌 Permite obtener los headers HTTP y el Blob
        responseType: 'blob' as 'json',
        withCredentials: true
      }
  );
}


  afiliar(path: string, afiliacion: IModel): Observable<IResponseData> {
    return this.http.post<IResponseData>(`${this.configService.servicio}:${this.configService.puerto}/${this.configService.app}/api/${path}/insertar`,
    afiliacion, { observe: 'body', responseType: 'json' });
  }

  consultar(path: string, afiliacion: IModel): Observable<IResponseData> {
    return this.http.post<IResponseData>(`${this.configService.servicio}:${this.configService.puerto}/${this.configService.app}/api/${path}/buscar`,
    afiliacion, { observe: 'body', responseType: 'json' });
  }

  activar(path: string, afiliacion: IAfiliacion): Observable<IResponseData> {
    return this.http.post<IResponseData>(`${this.configService.servicio}:${this.configService.puerto}/${this.configService.app}/api/${path}/activar`,
    afiliacion, { observe: 'body', responseType: 'json' });
  }

  bloquear(path: string, afiliacion: IAfiliacion): Observable<IResponseData> {
    return this.http.post<IResponseData>(`${this.configService.servicio}:${this.configService.puerto}/${this.configService.app}/api/${path}/bloquear`,
    afiliacion, { observe: 'body', responseType: 'json' });
  }

  desvincular(path: string, afiliacion: IAfiliacion): Observable<IResponseData> {
    return this.http.post<IResponseData>(`${this.configService.servicio}:${this.configService.puerto}/${this.configService.app}/api/${path}/desvincular`,
    afiliacion, { observe: 'body', responseType: 'json' });
  }

  sincronizar(path: string, afiliacion: IAfiliacion): Observable<IResponseData> {
    return this.http.post<IResponseData>(`${this.configService.servicio}:${this.configService.puerto}/${this.configService.app}/api/${path}/sincronizar`,
    afiliacion, { observe: 'body', responseType: 'json' });
  }

  obtenerData(path: string, request: IRequest): Observable<IResponseData> {
    return this.http.post<IResponseData>(`${this.configService.servicio}:${this.configService.puerto}/${this.configService.app}/api/${path}/data`,
    request, { observe: 'body', responseType: 'json' });
  }
}
