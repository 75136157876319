import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Constante } from '../../nucleo/constante/Constante';
import { Utilitario } from '../../nucleo/util/utilitario';

@Component({
  selector: 'app-modal-contacto',
  templateUrl: './modal-contacto.component.html',
  styleUrls: ['./modal-contacto.component.css'],
})
export class ModalContactoComponent implements OnInit {
  @Input() public contacto: any;
  showGeneralProgres = false;
  utilitario = new Utilitario();
  constructor(private modalService: NgbModal, private toastr: ToastrService) {}

  ngOnInit() {}

  guardar() {
    if (this.validarEmail(this.contacto.correo)) { // mejorar esta validación
      if (this.validarEmail(this.contacto.correo2)) {
        this.modalService.dismissAll(this.contacto);
      } else {
        this.toastr.warning(
          Constante.EMAIL_INVALIDO,
          Constante.ATENTION,
          this.utilitario.setOptionsToat()
        );
      }
    } else {
      this.toastr.warning(
        Constante.EMAIL_INVALIDO,
        Constante.ATENTION,
        this.utilitario.setOptionsToat()
      );
    }
  }

  public close(response?: any): void {
    this.modalService.dismissAll(response);
  }

  validarEmail(email: string): boolean {
    let response = true;
    if (email !== undefined && email !== null && email !== '') {
      response = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/.test(
        email
      );
    }
    return response;
  }
}
