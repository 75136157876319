import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ModalConfirmacionService {
  public titulo: string;
  public interrogante: string;
  public oculto: string = 'ocultar-modal';
  public notificacion = new EventEmitter<boolean>();
  
  constructor() { }

  ocultarModal() {
    this.oculto = 'ocultar-modal';
    this.titulo = null;
    this.interrogante = null;
  }

  mostrarModal(interrogante: string, titulo: string = 'Administrar Afiliación'): Promise<boolean> {
    let promesa = new Promise<boolean>((resolve)=> {
      this.oculto = '';
      this.interrogante = interrogante;
      this.titulo = titulo;
      this.notificacion.subscribe( response => {
        resolve(response)
      })
    })

    return (promesa)
  }

}
