import { Component, OnInit, Input } from "@angular/core";
import { ServicioService } from "../../servicio/servicio.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { AutenticacionService } from "../../seguridad/autenticacion.service";
import { Constante } from "../../nucleo/constante/Constante";
import { finalize } from "rxjs/operators";
import { Utilitario } from "../../nucleo/util/utilitario";

@Component({
  selector: "app-modal-editar-observado",
  templateUrl: "./modal-editar-observado.component.html",
  styleUrls: ["./modal-editar-observado.component.css"],
})
export class ModalEditarObservadoComponent implements OnInit {
  @Input() public producto: any;
  showGeneralProgres = false;
  utilitario = new Utilitario();

  constructor(
    private service: ServicioService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private autenticationService: AutenticacionService
  ) {}

  ngOnInit() {}

  public close(response?: any): void {
    this.modalService.dismissAll(response);
  }

  public guardar(): void {
    if (this.validateTipoDato()) {
      if (this.validatePrice()) {
        this.showGeneralProgres = true;
        this.service
          .guardar("preciovista/guardar", "msopmcovid", this.setRequest())
          .pipe(finalize(() => (this.showGeneralProgres = false)))
          .subscribe(
            (response) => {
              if (response.codigo === Constante.RESPUESTA_OK) {
                this.toastr.success(
                  response.mensaje,
                  "info ",
                  this.utilitario.setOptionsToat()
                );
                this.close(true);
              } else {
                this.toastr.error(
                  response.mensaje,
                  "Error",
                  this.utilitario.setOptionsToat()
                );
              }
            },
            (error: Error) => {
              console.log(error.message);
              this.toastr.error(
                Constante.MENSAJE_ERROR_CONEXION,
                "Error:",
                this.utilitario.setOptionsToat()
              );
            }
          );
      }
    }
  }

  private validatePrice(): boolean {
    let response = true;
    if (this.producto.tipoPrecio === 2) {
      if (
        Number(this.producto.precio3) > Number(this.producto.precio1) ||
        Number(this.producto.precio3) < Number(this.producto.precio2)
      ) {
        this.toastr.warning(
          Constante.MENSAJE_VALIDATE_TRES_PRECIOS,
          Constante.VALIDATION,
          this.utilitario.setOptionsToat()
        );
        response = false;
      }
    }
    return response;
  }
  private validateCero(valor: number): boolean {
    if (valor === 0) {
      this.toastr.warning(
        Constante.PRECIO_MAYOR_CERO,
        "Validación:",
        this.utilitario.setOptionsToat()
      );
      return false;
    }
    return true;
  }
  private validateTipoDato(): boolean {
    let response = true;
    if (this.producto.precio1) {
      const valor = Number(this.producto.precio1);

      if (isNaN(valor)) {
        this.toastr.warning(
          "El tipo de dato ingresado es incorrecto",
          "Validación:",
          this.utilitario.setOptionsToat()
        );
        response = false;
        return;
      } else {
        if (!this.validateCero(valor)) {
          response = false;
          return;
        }
      }
    }
    if (this.producto.precio2) {
      const valor = Number(this.producto.precio2);
      if (isNaN(valor)) {
        this.toastr.warning(
          "El tipo de dato ingresado es incorrecto",
          "Validación:",
          this.utilitario.setOptionsToat()
        );
        response = false;
        return;
      } else {
        if (!this.validateCero(valor)) {
          response = false;
          return;
        }
      }
    }
    if (this.producto.precio3) {
      const valor = Number(this.producto.precio3);
      if (isNaN(valor)) {
        this.toastr.warning(
          "El tipo de dato ingresado es incorrecto",
          "Validación:",
          this.utilitario.setOptionsToat()
        );
        response = false;
        return;
      } else {
        if (!this.validateCero(valor)) {
          response = false;
          return;
        }
      }
    }
    return response;
  }
  public setRequest() {
    return {
      entidad: {
        codEstab: this.producto.codEstab,
        codProdE: this.producto.codProd,
        codEstabOld: this.producto.codEstabOld,
        codProdEOld: this.producto.codProdOld,
        precio1: this.producto.precio1,
        precio2: this.producto.precio2,
        precio3: this.producto.precio3,
        tipoPrecio: this.producto.tipoPrecio,
        idLote: this.producto.idLote,
        tipoInsert: Constante.INSERT_OBSERVADO,
      },
      usuario: this.autenticationService.obtenerDatosUsuario().usuario,
    };
  }
}
