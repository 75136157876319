import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-modal-confirmar-si-no',
  templateUrl: './modal-confirmar-si-no.component.html',
  styleUrls: ['./modal-confirmar-si-no.component.css']
})
export class ModalConfirmarSiNoComponent implements OnInit {

  @Input() public data: any;
  @Input() closeMyModal: (any) => void;

  constructor() {
   
  }

  ngOnInit() {
  
  }

  close(response: boolean): void {
    this.closeMyModal(response);
  }
}
