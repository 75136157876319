import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http'; 

@Injectable()
export class SettingService {

    constructor(private http: HttpClient) {
        this.getJSON().subscribe(response => {
            localStorage.setItem('parametros' , JSON.stringify(response));
        })
    }

    private getJSON(): Observable<any> {
        return this.http.get("./assets/mesage.json");
    }

    public getMensaje(key: string): string {
        return JSON.parse(localStorage.getItem('parametros'))[key];
    }
}