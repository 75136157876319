import { EnvironmentService } from './environment.service';

export const EnvServiceFactory = () => {
    // Crear env
    const env = new EnvironmentService();
    const browserWindow = window || {};
    const browserWindowEnv = browserWindow['__env'] || {};

    for (const key in browserWindowEnv) {
        if (browserWindowEnv.hasOwnProperty(key)) {
            env[key] = window['__env'][key];
        }
    }
    return env;
};

export const EnvServiceProvider = {
    provide: EnvironmentService,
    useFactory: EnvServiceFactory,
    deps: [],
};
