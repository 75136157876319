import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-informativo-inicio',
  templateUrl: './modal-informativo-inicio.component.html',
  styleUrls: ['./modal-informativo-inicio.component.css']
})
export class ModalInformativoInicioComponent implements OnInit {

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  close(response: any): void {
    this.modalService.dismissAll(response);
  }

}
