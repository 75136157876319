import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';

import { ServicioService } from '../servicio/servicio.service';
import { Constante } from '../nucleo/constante/Constante';
import { IAfiliacion } from '../nucleo/interface/IAfiliacion';
import { ICombo } from '../nucleo/interface/ICombo';
import { IModel } from '../nucleo/interface/IModel';

export class CommonComponent {
  public afiliacion: IAfiliacion;
  public listTipoMedioAutenticacion: ICombo;
  public listTipoElementoSeguridad: ICombo;
  public tableData: Array<IAfiliacion>;
  public showGeneralProgres: boolean;
  public mensage: any;

  constructor(public service: ServicioService, public toastr: ToastrService) {
    this.afiliacion = {
      codigoTipoMedioAut: '',
      tipoMedioAut: '',
      codigoTipoElementoSeg: '',
      codigoPrimario: '',
      codigoSecundario: '',
    };
  }

  limpiar(tipo?: string, formulario: NgForm = null) {
    switch (tipo) {
      case '1':
        formulario.resetForm({ codigoTipoMedioAut: '' });
        this.tableData = [];
        break;
      case '2':
        formulario.resetForm({ codigoTipoElementoSeg: '' });
        this.tableData = [];
        break;
      case '3':
        formulario.resetForm({ codigoTipoMedioAut: '' });
        this.tableData = [];
        break;
      default:
        this.afiliacion = {
          tipoMedioAut: '',
          codigoTipoMedioAut: '',
          codigoTipoElementoSeg: '',
          codigoPrimario: '',
          codigoSecundario: '',
        };
        break;
    }
  }

  consultar(tipo: string) {
    this.afiliacion.tipoConsulta = tipo;
    this.showGeneralProgres = true;
    const model: IModel = {
      filtro: this.afiliacion,
      entidad: null,
      usuario: 'carlos',
    };

    this.service
      .consultar('afiliacion', model)
      .pipe(finalize(() => (this.showGeneralProgres = false)))
      .subscribe(
        (response) => {
          if (response.codigo === Constante.RESPUESTA_OK) {
            this.tableData = response.data;
          } else {
            this.toastr.warning(
              response.mensaje + ' ' + response.mensajeAdicional,
              response.codigo,
              this.setOptionsToat()
            );
          }
        },
        (error: Error) => {
          console.log(error.message); //toast
          this.toastr.error(error.message, 'Error:', this.setOptionsToat());
        }
      );
  }

  setOptionsToat() {
    return {
      timeOut: 5000,
      progressBar: true,
      closeButton: true,
    };
  }

  actualizarConsulta(evento: boolean, tipoConsulta: string) {
    if (evento) {
      this.consultar(tipoConsulta);
    }
  }

  fechaActual(){
    var d = new Date();
    var datestring = ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" +
    d.getFullYear() + "T" + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
    return datestring;
  }
}
