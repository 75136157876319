import { Component, OnInit } from '@angular/core';
import { ModalConfirmacionService } from './modal-confirmacion.service';

@Component({
  selector: 'app-modal-confirmacion',
  templateUrl: './modal-confirmacion.component.html',
  styleUrls: ['./modal-confirmacion.component.css']
})
export class ModalConfirmacionComponent implements OnInit {

  constructor(public modalService: ModalConfirmacionService) { }

  ngOnInit() {

  }

  cerrarModal(){
    this.modalService.ocultarModal();
    this.modalService.notificacion.emit(false);
  }

  aceptar() {
    this.modalService.ocultarModal();
    this.modalService.notificacion.emit(true);
  }

}
