import { Component, OnInit, Input } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-modal-confirmar",
  templateUrl: "./modal-confirmar.component.html",
  styleUrls: ["./modal-confirmar.component.css"],
})
export class ModalConfirmarComponent implements OnInit {
  @Input() public data: any;
  @Input() closeMyModal: (any) => void;
  checked = false;
  botonAceptarActivo = true;
  oculto = true;
  constructor(private modalService: NgbModal) {
   
  }

  ngOnInit() {
    this.validarBoton();
  }

  close(response: boolean): void {
      this.closeMyModal(response);
  }

  checkValue(): void {
    if (this.checked) {
      this.botonAceptarActivo = false;
    } else {
      this.botonAceptarActivo = true;
    }
  }

  validarBoton(): void {
    if(!this.data.mostrarSugerido) {
      this.botonAceptarActivo = false;
    } 
  }
}
