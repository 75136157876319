import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-suspension',
  templateUrl: './modal-suspension.component.html',
  styleUrls: ['./modal-suspension.component.css']
})

export class ModalSuspensionComponent implements OnInit {
  @Input() public img_src;
  @Input() public link_url;
  @Input() public modal_class;
  modal_link: boolean = false;
  public isActive: boolean = true;
  constructor(private modalService: NgbModal) { }

  ngOnInit() {
    const flyerData = this.getFlyerData();
    if (!flyerData) {
      this.close(false);
      this.isActive = false;
    } else {
      this.img_src = flyerData.img;
      this.link_url = flyerData.link;
    }
    if (this.modal_class === 'modal-sus') {
      this.modal_link = true;
    }
  }

  close(response: any): void {
    this.modalService.dismissAll(response);
  }

  goToLink(url: string){
    if (this.modal_link){
      window.open(url, "_blank");
    }
  }



  getFlyerData(): { img: string, link: string } {
    const currentDate = new Date();
    const flyer1EndDate = new Date('2024-06-12T12:00:00');

    const flyer2StartDate = new Date('2024-06-12T12:00:00');
    const flyer2EndDate = new Date('2024-06-19T12:00:00');

    const flyer3StartDate = new Date('2024-06-19T12:00:00');
    const flyer3EndDate = new Date('2024-06-26T12:00:00');



    if (currentDate <= flyer1EndDate) {
      return { img: './../../../assets/images/Flyer 1.jpeg', link: 'https://minsa-gob-pe.zoom.us/j/95126166429' };
    } else if (currentDate >= flyer2StartDate && currentDate <= flyer2EndDate) {
      return { img: './../../../assets/images/Flyer 2.jpeg', link: 'https://minsa-gob-pe.zoom.us/j/95126166429' };
    } else if (currentDate >= flyer3StartDate && currentDate <= flyer3EndDate) {
      return { img: './../../../assets/images/Flyer 3.jpeg', link: 'https://minsa-gob-pe.zoom.us/j/94596856800' };
    } else {
      return null;
    }
  }

}
