import { Component, OnInit, Input } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ServicioService } from "../../servicio/servicio.service";
import { AutenticacionService } from "../../seguridad/autenticacion.service";
import { finalize } from "rxjs/operators";
import { Constante } from "../../nucleo/constante/Constante";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-modal-enviar-correo",
  templateUrl: "./modal-enviar-correo.component.html",
  styleUrls: ["./modal-enviar-correo.component.css"],
})
export class ModalEnviarCorreoComponent implements OnInit {
  @Input() public lote;
  entidad: any = {};
  establecimiento: any = {};
  showGeneralProgres = false;
  constructor(
    private modalService: NgbModal,
    private servicio: ServicioService,
    private autenticatioService: AutenticacionService,
    public toastr: ToastrService
  ) {}

  ngOnInit() {
    this.entidad.emailCC = this.autenticatioService.obtenerDatosUsuario().emailAdmin;
  }

  close(): void {
    this.modalService.dismissAll();
  }

  enviar(): void {
    if (this.validarEmail(this.entidad.email)) {
      this.showGeneralProgres = true;
      this.servicio
        .postGeneric("lote/enviarcorreo", "msopmcovid", this.setRequest())
        .pipe(finalize(() => (this.showGeneralProgres = false)))
        .subscribe(
          (response) => {
            if (response.codigo === Constante.RESPUESTA_OK) {
              this.toastr.success(
                response.mensaje,
                "Correo enviado",
                this.setOptionsToat()
              );
              this.close();
            } else {
              this.toastr.error(
                response.mensaje,
                "Error",
                this.setOptionsToat()
              );
            }
          },
          (error: Error) =>
            this.toastr.error(
              Constante.MENSAJE_ERROR_CONEXION,
              "Error",
              this.setOptionsToat()
            )
        );
    } else {
      this.toastr.warning(
        Constante.EMAIL_INVALIDO,
        Constante.ATENTION,
        this.setOptionsToat()
      );
    }
  }

  setRequest() {
    return {
      filtro: {
        idLote: this.lote.idLote,
        usuario: this.autenticatioService.obtenerDatosUsuario().usuario,
        mensaje: this.entidad.mensaje,
        emailDestino: this.entidad.email,
        tituloMensaje: "Información de envío de archivo excel",
        template: "enviar-comprobante.html",
        emailCC: this.entidad.emailCC,
        tipoEnvio: "PROCESADO",
      },
    };
  }

  setOptionsToat() {
    return {
      timeOut: 5000,
      progressBar: true,
      closeButton: true,
    };
  }

  validarEmail(email: string): boolean {
    let response = true;
    if (email !== undefined && email !== null && email !== "") {
      response = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/.test(
        email
      );
    }
    return response;
  }
}
