export class Constante {
  static APP_VERSION = '1.2.0';
  static RESPUESTA_OK = '00';
  static RESPUESTA_PROCESANDO = '95';
  static RESPUESTA_OK_WS = 'C0001';
  static RESPUESTA_OK_ACT = 'C0024';
  static RESPUESTA_OK_BLK = 'C0020';
  static RESPUESTA_OK_DSV = 'C0018';
  static RESPUESTA_OK_SIN = 'C0059';
  static ERROR_99 = '99';

  // tokenDEV_google
  static keyTokenDev = '6Lf9PLUZAAAAAGZ6uhEb67dIOEqBZstWV9epDcTZ';
  static keyTokenPrd = '6LfTRbUZAAAAAC4PZ6QF3Oz_IElVRZpnW9zzA7JD';

  // Acciones
  static ACCION_ACTIVAR = 'ACTIVAR';
  static ACCION_BLOQUEAR = 'BLOQUEAR';
  static ACCION_DESVINCULAR = 'DESVINCULAR';
  static ACCION_SINCRONIZAR = 'SINCRONIZAR';
  // MENSAJES
  static TITULO_ACTIVAR = 'Activar Afiliación';
  static TITULO_BLOQUEAR = 'Bloquear Afiliación';
  static TITULO_DESVINCULAR = 'Desvincular Afiliación';
  static TITULO_SINCRONIZAR = 'Sincronizar Afiliación';

  //static SERVICIO = '192.168.79.43'; // 93.104.215.212 //192.168.1.16 // 192.168.79.43 digemid server
  //static PUERTO = '8480';

  // MENSAJES DE NIVEL CLIENTE
  static MENSAJE_ERROR_CONEXION = '¡No se pudo conectar con el SERVIDOR!';
  static EMAIL_INVALIDO = 'El email ingresado no es válido';
  static SIN_DATA = 'No hay resultados que mostrar';
  static SIN_PRODUCTO_POR_DESCARGAR = 'Debe elegir un medicamento válido';
  static SIN_DATA_POR_EXPORTAR = 'No hay data que exportar';
  static PRODUCTO_NO_EXISTE =
    'Su producto puede estar inactivo o no existe en el Catálogo de Productos Farmacéuticos actualizado diariamente. En caso de una consulta comunicarse a los correos: preciosdigemid@minsa.gob.pe y ahorromed@minsa.gob.pe';
  static PRODUCTO_NO_EXISTE_ADMINISTRADO =
    'Su producto puede estar inactivo o no existe en el Catálogo de Productos Farmacéuticos actualizado diariamente, en caso de consulta comunicarse a oppfcodificacion@minsa.gob.pe; en caso que sean otros tipos de consultas del OPPF contactarse con preciosdigemid@minsa.gob.pe / ahorromed@minsa.gob.pe';
  static PRECIO_MAYOR_CERO =
    'Los precios ha registrar deberán ser mayores a 0.00';
  static MENSAJE_VALIDATE_TRES_PRECIOS =
    'El precio mediana debe ser mayor o igual al precio mínimo y menor o igual al precio máximo';
  static MENSAJE_VALIDATE_PLAZO_OBSERVADOR =
    'No se permite la corrección de los registros observados posterior al cierre del mes de la obligación.';
  static FORMA_NO_EXISTE =
    'No se encontró la forma farmaceutica ingresada';
  static TITULAR_NO_EXISTE =
    'No se encontró el titular ingresado';
  static LAB_NO_EXISTE =
    'No se encontró el laboratorio ingresado';


  // SERVICIOS
  static ESTABLECIMIENTO = 'establecimiento';
  static PRODUCTO = 'producto';
  static USUARIO = 'usuario';
  static PARAMETRO = 'parametro';
  static OBSERVADO = 'observado';
  static PRECIO_PRODUCTO = 'precioproducto';
  static PRECIO_VISTA = 'preciovista';
  static LOTE = 'lote';
  static CONTACTO = 'contacto';
  static PRECIO_MENSUAL = 'preciomensual';

  // CRUD
  static LISTAR = 'listar';
  static GUARDAR = 'guardar';
  static EDITAR = 'editar';
  static OBTENER = 'obtener';
  static ELIMINAR = 'eliminar';
  static VER = 'ver';

  // microservices
  static OPMCOVID = 'msopmcovid';
  static MSCONSULTA = 'msconsulta';

  // banderas
  static INSERT_VALIDO = '01';
  static INSERT_OBSERVADO = '02';

  //Headers Toaster
  static VALIDATION = 'Validación';
  static ATENTION = 'Atención';
  static INFO = 'info';
  static ERROR = 'Error';

  //EXTENSIONES
  static EXTENSION_XLS = '.xls';
  static EXTENSION_XLSX = '.xlsx';
  static EXTENSION_CSV = '.csv';

  //TIPO ESTABLECIMIENTO
  static ESTABLECIMIENTO_OTROS = '0';
  static ESTABLECIMIENTO_PUBLICO = '1';
  static ESTABLECIMIENTO_PRIVADO = '2';
  static LABORATORIOS_DROGUERIAS = '3';

  //CANTIDAD DE PRECIOS A REPORTAR
  static UN_PRECIO = 0;
  static DOS_PRECIOS = 1;
  static TRES_PRECIOS = 2;

  //TIPO FORM PARA PRECIO NUEVO
  static FORM_UN_PRECIO = '03';
  static FORM_DOS_PRECIOS = '02';
  static FORM_TRES_PRECIOS = '01';

  //CATEGORIA ESTABLECIMIENTOS
  static LABORATORIO = '01';
  static DROGUERIA = '02';
  static FARMACIA = '03';
  static BOTICA = '04';
  static BOTIQUIN = '05';
  static SERVICIO_DE_FARMACIA = '06';
  static ALMACEN_ESPECIAL = '07';
  static IMPORTADORA = '08';
  static LINEA = '09';

  static SYSTEM_TITLE =
    'SISTEMA NACIONAL DE INFORMACIÓN DE PRECIOS DE PRODUCTOS FARMACÉUTICOS - SNIPPF';
  static WELCOME_MESSAGE =
    'BIENVENIDOS AL OBSERVATORIO PERUANO DE PRODUCTOS FARMACÉUTICOS - OPPF';
  static LOGO_MINSA = 'assets/images/logo_minsa_copia.JPG';
  static LOGO_TELESALUD = 'assets/images/telesalud-v2.png';

  static FONDO_OPM = 'assets/images/fondo_opm.jpg';
  static TITULO_OPM = 'assets/images/titulo_opm.png';
  static FONDO_OPM_MOVIL = 'assets/images/fondo_opm_movil.jpg';
  static TITULO_OPM_MOVIL = 'assets/images/titulo_opm_movil.png';

  static NOTA_REPORTE_PRECIOS =
    'Sólo se visualizarán  los productos del mes actual de reporte; en caso que aún no reporta en el mes actual, la vista estaría en blanco hasta que proceda a reportar.';
  static TEXTO_HEADER_CARGA_MASIVA =
    'Para cargar un archivo mediante este método de envío (Archivo Excel) debes descargar la plantilla (ver ¿Cómo Elegir?) para llenar los datos a reportar, luego guardar el archivo con la extensión CSV (DELIMITADO POR COMAS), para así proceder a comprimir el (los) archivo(s) en formato ZIP. Dicho archivo debe pesar como máximo 4MB.';
  static TEXTO_NOTA_CARGA_MASIVA =
    'Nota: Tener en cuenta que para ambos tipos de proceso, se debe de cumplir con la estructura y las consideraciones para la carga de un archivo Excel (ver párrafo anterior).';
  static TEXTO_MODAL_REPROCESAR =
    'Es responsabilidad del administrado y acepta las posibles investigaciones por precio y su correspondiente sanción por parte de las diversas autoridades (SUSALUD, INDECOPI, MINSA)';
  static TEXTO_ACTUALIZACION_ESTABLECIMIENTO =
    'Cualquier modificación debe ser mediante el ';
  static TEXTO_REPORTE =
    'Para descargar los reportes se deberá hacer clic sobre el ícono de reportes. Para buscar el establecimiento del cual se desea descargar el reporte puede hacer uso de los filtros de búsqueda.';

  static MENUITEMS = [
    {
      label: '',
      main: [
        {
          state: 'precio-productos',
          name: 'Envío de Precios',
          type: 'sub',
          icon: 'ti-layout-grid2-alt',
          rol: 'ADMINISTRADO',
          children: [
            {
              state: 'precio-producto',
              name: 'Mediante llenado de formulario',
              type: 'link',
              icon: 'ti-layout-sidebar-left',
            },
            {
              state: 'listar-carga-masiva',
              name: 'Envío de información mediante archivo excel',
              type: 'link',
              icon: 'ti-layout-sidebar-left',
            },
            {
              state: 'web-service',
              name: 'Mediante web service',
              type: 'link',
              icon: 'ti-layout-sidebar-left',
            },
          ],
        },
        /*
        {
          state: 'precio-covid',
          name: 'Envío de Precios COVID-19 \n (DU 059-2020)',
          type: 'sub',
          icon: 'ti-support',
          rol: 'ADMINISTRADO',
          children: [
            {
              state: 'precio-producto',
              name: 'Mediante llenado de formulario',
              type: 'link',
              icon: 'ti-layout-sidebar-left',
            },
            {
              state: 'listar-carga-masiva',
              name: 'Envío de información mediante archivo excel',
              type: 'link',
              icon: 'ti-layout-sidebar-left',
            }
          ],
        },
        */
        {
          state: 'solicitud',
          name: 'Solicitudes',
          type: 'sub',
          icon: 'ti-support',
          rol: 'ADMINISTRADO',
          children: [
            {
              state: 'solicitud-producto',
              name: 'Solicitud de nuevo código del OPPF',
              type: 'link',
              icon: 'ti-layout-sidebar-left',
            }
          ],
        },
        {
          state: 'reportes',
          name: 'Reportes',
          type: 'sub',
          icon: 'ti-bar-chart',
          rol: 'ADMINISTRADO',
          children: [
            {
              state: 'reporte-precios',
              name: 'Reporte de precios',
              type: 'link',
              icon: 'ti-layout-sidebar-left',
            },
            {
              state: 'reporte-inscripcion',
              name: 'Constancia de inscripción',
              type: 'link',
              icon: 'ti-layout-sidebar-left',
            },
          ],
        },
        {
          state: 'atencion-consultas',
          name: 'Atención a consultas',
          type: 'sub',
          icon: 'ti-headphone-alt',
          rol: 'ADMINISTRADO',
          children: [
            {
              state: 'establecimientos',
              name: 'Consultas',
              type: 'link',
              icon: 'ti-layout-sidebar-left',
            },
            {
              state: 'change-password',
              name: 'Cambiar Contraseña',
              type: 'link',
              icon: 'ti-layout-sidebar-left',
            },
          ],
        },
        {
          state: 'configuracion',
          name: 'Configuración',
          type: 'sub',
          icon: 'ti-settings',
          rol: 'GESTOR',
          children: [
            {
              state: 'diris',
              name: 'Contactos',
              type: 'link',
              icon: 'ti-layout-sidebar-left',
            },
          ],
        },
        {
          state: 'cerrar-sesion',
          name: 'Cerrar sesión',
          type: 'sub',
          icon: 'ti-power-off',
          rol: 'ADMINISTRADO',
        },
      ],
    },
  ];
}
