import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-comunicacion',
  templateUrl: './modal-comunicacion.component.html',
  styleUrls: ['./modal-comunicacion.component.css']
})

export class ModalComunicacionComponent implements OnInit {
  @Input() public img_src;
  @Input() public modal_class;
  modal_link: boolean = false;
  constructor(private modalService: NgbModal) { }

  ngOnInit() {
    if (this.modal_class === 'modal-sus'){
      this.modal_link = true;
    }
  }

  close(response: any): void {
    this.modalService.dismissAll(response);
  }

  goToLink(url: string){
    if (this.modal_link){
      window.open(url, "_blank");
    }
  }

}
